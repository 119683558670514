import React  from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './app/config/themaConfig'

import { Dashboard } from './dashboard';
import { useSelector } from 'react-redux';
import { selectUserPhone } from './user/domain/userPhoneSlice';
import { UserAdmin, IndexLoginPage } from './user';
import PrestapolisPublic from './prestapolis/route';
import { Menus as menuPrestapolis } from './prestapolis/menu/menuJson'
import withAuthentication from './user/componet/session/withAuthentication';
import { Prestapolis } from './prestapolis';
import {CHANGE_PASS} from '../src/app/api-client/constants'


function App() {

  const isUserChange = localStorage.getItem(CHANGE_PASS)
  const user = useSelector(selectUserPhone);
  const menu = menuPrestapolis();

  const isUser = isUserChange === "isChange"? false:user;

  
  return (
    <ThemeProvider theme={theme}>
      {isUser ?
        <Dashboard
          menu={menu}
        >
          <Prestapolis />
          <UserAdmin />
        </Dashboard> :
        <PrestapolisPublic />
      }
    </ThemeProvider>


  );
}

export default withAuthentication(App);
