import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import CancelIcon from '@material-ui/icons/Cancel';

import MenuMobile from './MenuMobile';
import MenuDesktop from './MenuDesktop';
import useStyles from './UseStyle';
import ListMenuItem from '../Menu/ListMenuItem';
import { useDispatch } from 'react-redux';
import { logout } from '../../../user/domain/userPhoneSlice';
import { getSucursalData, getSucursalDataByIdUser, getProductsByCommerceId } from "../../../prestapolis/domain/api/PrestapolisApi"
import { useHistory } from "react-router-dom";
import { ID_SUCURSAL, ID_USER, ID_COMERCIO, USER_ROL, ISADMIN_USER, NAME_COMERCIO, NAME_SUCURSAL, URL_COMERCIO, ID_SELLER, CODE_COMERCIO, RECAUDO, SUCURSAL } from "../../../app/api-client/constants"
import Button from '@material-ui/core/Button'
import ModalCamabiarContraseña from '../../../prestapolis/modals/modalCambioPasword';
import { set } from 'date-fns';
import Grid from '@material-ui/core/Grid'
import ModalCalculadoraCreate from '../../../prestapolis/modals/modalCalculadoraCreate';






function Header({ title, _menus }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [numNotifications, setNumNotifications] = useState(0);
    const [nombreVendedor, setnombreVendedor] = useState("")
    const [comercio, setComercio] = useState("")
    const [sucursal, setSucursal] = useState("")
    const [data, setData] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [urlCommerce, seturlCommerce] = useState("")
    const [catalogoState, setcatalogoState] = useState(false);
    const [estados, setEstados] = useState({
        visible: false,
        showLoad: false,
        filter: "",
        title: "",
        message:
            "Debes calcular y seleccionar un plan de pago acorde a tus preferencias",
        type: "",
        catalogo: [0],
    });
    const [comerceData, setComerceData] = useState({});
    const [prodComerce, setprodComerce] = useState([]);

    const [isOpenCalc, setisOpenCalc] = useState(false)
    const [recaudo, setRecaudo] = useState("")

    const history = useHistory();
    let idUser = localStorage.getItem(ID_USER)
    let rol = localStorage.getItem(USER_ROL)
    let rec = localStorage.getItem(RECAUDO)
    let isAdmin = localStorage.getItem(ISADMIN_USER)
    let urlService = localStorage.getItem(URL_COMERCIO)
    let nameSucursal = localStorage.getItem(NAME_SUCURSAL)
    let nameCommerce = localStorage.getItem(NAME_COMERCIO)
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const dispatch = useDispatch();


    const handledModalChange = () => {
        setOpenModal(!openModal)
        handleMenuClose()
    }


    const LabelRol = () => {
        switch (rol) {
            case "ROLE_USER":
                return "Usuario"
            case "ROLE_ADMIN":
                return "Administrador"
            case "ROLE_COMMERCE_ADMIN":
                return "Admin Comercios"
            case "ROLE_BRANCH_ADMIN":
                return "Admin Sucursal"
            case "ROLE_SELLER":
                return "Vendedor"
            case "ROLE_COLLECTOR":
                return ""
            default:
                return "No rol"
        }
    }



    const ProfileRol = () => {
        switch (rol) {
            case "ROLE_USER":
                return "Usuario"
            case "ROLE_ADMIN":

                return (
                    <>
                        <MenuItem onClick={handleMenuClose}>{`Rol: ${LabelRol()}`}</MenuItem>
                        <MenuItem onClick={handledModalChange}>{`Cambiar Contraseña`}</MenuItem>
                        <hr />
                        <MenuItem onClick={handleLogout}>Salir</MenuItem>
                    </>
                )

            case "ROLE_COMMERCE_ADMIN":
                return (
                    <>
                        <MenuItem onClick={handleMenuClose}>{`Comercio: ${comercio}`}</MenuItem>
                        <MenuItem onClick={handleMenuClose}>{`Rol: ${LabelRol()}`}</MenuItem>
                        <MenuItem onClick={handledModalChange}>{`Cambiar Contraseña`}</MenuItem>
                        <hr />
                        <MenuItem onClick={handleLogout}>Salir</MenuItem>
                    </>
                )
            case "ROLE_BRANCH_ADMIN":
                return (
                    <>
                        <MenuItem onClick={handleMenuClose}>{`Comercio: ${comercio}`}</MenuItem>
                        {/* <MenuItem onClick={handleMenuClose}>{`Sucursal: ${nameSucursal}`}</MenuItem> */}
                        <MenuItem onClick={handleMenuClose}>{`Rol: ${LabelRol()}`}</MenuItem>
                        <MenuItem onClick={handledModalChange}>{`Cambiar Contraseña`}</MenuItem>
                        <hr />
                        <MenuItem onClick={handleLogout}>Salir</MenuItem>
                    </>
                )
            case "ROLE_SELLER":
                return (
                    <>
                        <MenuItem onClick={handleMenuClose}>{`Usuario: ${nombreVendedor}`}</MenuItem>
                        <MenuItem onClick={handleMenuClose}>{`Comercio: ${comercio}`}</MenuItem>
                        <MenuItem onClick={handleMenuClose}>{`Rol: ${LabelRol()}`}</MenuItem>
                        <hr />
                        <MenuItem onClick={handleLogout}>Salir</MenuItem>
                    </>
                )
            case "ROLE_COLLECTOR":
                return ""
            default:
                return "No rol"
        }
    }

    const ResetRut = () => {
        history.push(`/`);
    }

    useEffect(async () => {
        //filter("");
        ResetRut()
        if (idUser != null) {
            await getSucursalByid(idUser)
        }
    }, [idUser], [])

    const getSucursalByid = async (id) => {
        setcatalogoState(false);
        
       await getSucursalDataByIdUser(dispatch, id)
            .then((response) => {

                let nombrecompleto = validateUndeNull(response.respuesta.nombreCompleto);

                let comercio = response.respuesta.comercio !== null && response.respuesta.comercio !== undefined
                    ? response.respuesta.comercio.nombre
                    : "Sin datos"

                
                let code_comercio = response.respuesta.comercio !== null && response.respuesta.comercio !== undefined
                ? response.respuesta.comercio.apikey
                : "Sin datos"

                let urlCommerce = response.respuesta.comercio !== null && response.respuesta.comercio !== undefined
                    ? response.respuesta.comercio.url
                    : "Sin datos"

                let idSucursal = response.respuesta.sucursales !== null && response.respuesta.sucursales
                    ? response.respuesta.sucursales
                    : "Sin datos"

                let idComercio = response.respuesta.comercio !== null && response.respuesta.comercio !== undefined
                    ? response.respuesta.comercio.id
                    : "Sin datos"
                
                let recaudo = response.respuesta.recaudo !== null && response.respuesta.recaudo !== undefined
                ? response.respuesta.recaudo
                : "Sin datos" 
                
     

                let idSeller = validateUndeNull(response.respuesta.id);
                


                setnombreVendedor(nombrecompleto);
                setComercio(comercio);
                seturlCommerce(urlCommerce);
                setRecaudo(recaudo);

                localStorage.setItem(ID_SUCURSAL, idSucursal);
                localStorage.setItem(ID_COMERCIO, idComercio);
                localStorage.setItem(URL_COMERCIO, urlCommerce);
                localStorage.setItem(ID_SELLER, idSeller);
                localStorage.setItem(CODE_COMERCIO, code_comercio);
                localStorage.setItem(RECAUDO, recaudo);
                localStorage.setItem(SUCURSAL, response.respuesta.comercio.nit);
                
   
            })
            .catch((err) => {
            });


      //Se verifica qeu tenga catalogo el comercio
       await loadInitCatalogo(localStorage.getItem(ID_COMERCIO));
    }

    const validateUndeNull = (value) => {
        if (value !== null && value !== undefined) {
            return value;
        } else {
            return "Sin dato"
        }
    }


    const ResetDash = () => {
        localStorage.setItem(USER_ROL, "ROLE_ADMIN")
        localStorage.setItem(ID_SUCURSAL, "")
        localStorage.setItem(ID_COMERCIO, "")
        localStorage.setItem(ID_USER, "")
        localStorage.setItem(RECAUDO, "")
        localStorage.setItem(SUCURSAL, "")
        history.push(`/`);
        window.location.reload()

    }

    //Eventos de open y close menu
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        handleMenuClose();
        history.push(`/`);
        dispatch(logout());

    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    //METODO PARA FILTRAR CATALOGO
    const loadInitCatalogo = (id) => {
        setcatalogoState(false);

        if(id == null || id == 'Sin datos'){
            return;
        }

        let catalogo = [];
        getProductsByCommerceId(dispatch, id, "")
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    response.respuesta.map((item) =>
                        catalogo.push({
                            ...item,
                            estructuraCuotas: JSON.parse(item.estructuraCuotas),
                        })
                    );
                    setprodComerce(catalogo);
                    setcatalogoState(true);
                } else {
                    setcatalogoState(false);
                }
            })
            .catch((err) => {
                setcatalogoState(false);
            });
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {ProfileRol()}
        </Menu>
    );

    const MobileMenuId = 'primary-search-account-menu-mobile';

    return (
        <>
            <ModalCalculadoraCreate
                setopenMondal={setisOpenCalc}
                isOpen={isOpenCalc}
                isCatalogo={catalogoState}
                catalogos={prodComerce}
            />
            <ModalCamabiarContraseña
                isOpen={openModal}
                setopenMondal={setOpenModal}
            />

            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {title}
                    </Typography>

                    {isAdmin === "isAdmin" && <Button
                        variant="outlined"
                        color="inherit"
                        size="large"
                        startIcon={<RotateLeftIcon />}
                        onClick={(e) => { ResetDash() }}
                    >
                        Deshacer cambios
                    </Button>}

                    <div style={{ padding: "5px", margin: "0 auto", textAlign: "center" }}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            size="large"
                            onClick={handleLogout}
                            startIcon={<CancelIcon/>}
                        >
                            Cerrar sesion
                        </Button>
                    </div>


                    {rol !== "ROLE_ADMIN" &&
                        <div style={{ padding: "5px", margin: "0 auto", textAlign: "center" }}>
                            <Button
                                 
                                variant="outlined"
                                color="inherit"
                                onClick={() => {setisOpenCalc(true)}}
                            >
                                Crear Solicitud
                            </Button>
                        </div>
                    }

                    <div className={classes.sectionDesktop}>
                        <MenuDesktop
                            menuId={menuId}
                            handleProfileMenuOpen={handleProfileMenuOpen}
                            numNotifications={numNotifications}
                        />
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={MobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <MenuMobile
                handleProfileMenuOpen={handleProfileMenuOpen}
                isMobileMenuOpen={isMobileMenuOpen}
                handleMobileMenuClose={handleMobileMenuClose}
                mobileMoreAnchorEl={mobileMoreAnchorEl}
                MobileMenuId={MobileMenuId}
                numNotifications={numNotifications}
            />
            {renderMenu}
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <ListMenuItem _menus={_menus} />

                {rol !== "ROLE_ADMIN" &&
                    <div style={{ padding: "5px", margin: "0 auto", textAlign: "center" }}>
                        <Button
                            variant="contained"
                            color='primary'
                            onClick={() => { setisOpenCalc(true)}}
                        >
                            Crear Solicitud
                        </Button>
                    </div>
                }


                {/* <Divider />
                <List>{mainListItems}</List>
                <Divider />
                <List>{secondaryListItems}</List> */}
            </Drawer>
        </>
    );
}

export default Header;
