//React
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";

//Redux
import { ID_SUCURSAL, ID_COMERCIO } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';


//Icons Material
import {
    Cancel,
    CreateNewFolder,
    CheckCircle
} from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

//Components Material
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import {
    Paper,
    Grid,
    TextField,
    Button,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Fab,
} from '@material-ui/core';


//Local Componet
import {
    getParameters,
    aprovateSolicitud,
    sentRechazo,
    geSolicitudesById,
} from '../domain/api/PrestapolisApi'
import MigaPan from '../../app/orussystem/component/MigaPan';
import { formatNumberMoney } from '../utils/validation';
import ModalGeneric from '../modals/modalGeneric';


//My syle
const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },

    div: {
        margin: '0 auto',
        minWidth: '40%',
    },
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
        margin: '0 auto',
        flexDirection: 'column',
    },
    paperPhoto: {
        padding: theme.spacing(0),
        display: 'flex',
        margin: '0 auto',
        maxWidth: '500px',
        overflow: 'auto',
        flexDirection: 'column',
    },
    btnFactura: {
        margin: '0px !important',
        textAlign: 'center',
        justifycontent: 'center'
    },
    button: {
        color: '#ff0000 !important',
    },
    img: {
        maxWidth: '1000px',
    },
}));



export const AsignarSolicitud = () => {

    const [showLoad, setshowLoad] = useState(false)
    const [messgeFile, setMessageFile] = useState("")
    const [fileUpload, setfileUpload] = useState(false)
    const [fileName, setfileName] = useState("")
    const [fileUploadErr, setfileUploadErr] = useState(false)
    const [valueMotivo, setMotivo] = useState("")
    const [Data, setData] = useState([])
    const [paramRechazo, setparamRechazo] = useState([])
    const [idTipoSoli, setidTipoSoli] = useState(0)
    const [valueAbono, setvalueAbono] = useState("0")
    const [valueProducto, setvalueProducto] = useState("0")
    const [foto, setfoto] = useState("")
    const [isOpen, setisOpen] = useState(false)
    const [message, setmessage] = useState("")
    const [errAprobar, seterrAprobar] = useState(false)
    const [messageErro, setmessageErro] = useState("")
    const [facturaMessage, setfacturaMessage] = useState("Cargar factura")
    const [facturaFile, setFacturaFile] = useState("")
    //<<<<<<< HEAD
    //const [aprovFoto, setaprovFoto] = useState(false)


    //  const disabledApr = (valueAbono !== "0" || valueProducto !== "0") && fileName !== "" && aprovFoto ? false : true;
    //=======


    const disabledApr = (valueAbono !== "0" || valueProducto !== "0") && fileName !== "" ? false : true;
    const disableDen = valueMotivo === "" ? true : false;
    let idSucursal = localStorage.getItem(ID_SUCURSAL)
    let idComercio = localStorage.getItem(ID_COMERCIO)
    const history = useHistory();
  
    const classes = useStyles();
    let { id } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {

        getSolicitudById(id)
        getParametersList(0, 150, "MOTIVO_RECHAZO")

    }, [])


    const getSolicitudById = (id) => {
        geSolicitudesById(dispatch, id)
            .then((response) => {
                if (response.code === "0000") {
                    let data = response.content[0];
                    let idTipo = data.tipoSolicitud.id;
                    setidTipoSoli(idTipo);
                   
                    let foto = data.resultadoSolicitud.fotoPagare;

                    let fotoSoli = `https://${process.env.REACT_APP_AWS_BUCKET}/` + foto;

                    let fotoFull = foto === null ? "https://cdn.prestapolis.com/logos/imagen_no_encontrada.png" : fotoSoli;
                    setfoto(fotoFull)
                    setData(data);
                } else {
                    
                }
            })
            .catch((error) => {
             
                if (error.status === 401) {

                }
            });

    };



    // PUT http://localhost:8092/v2/quotas/setFactura
    // Content-Type: application/json
    // {
    //     "id": 3692,
    //     "resultadoSolicitud": {
    //         "factura":"factura_desde_el_services_dfgasfdfgasdfg"
    //     }
    // }

    const AprobarSolicitud = (id) => {

        let abono = formatNumberMoney(Data.abonoFinal);
        let valorMonto = formatNumberMoney(Data.valormontorequerido);
        let valueInput = idTipoSoli != 5 ? valueAbono : valueProducto;
        let valueData = idTipoSoli != 5 ? abono : valorMonto;
        let messageProd = idTipoSoli != 5 ? "abono" : "próducto";

        const request = {
            id: id,
            resultadoSolicitud: {
                factura: facturaFile
            }
        }

        if (valueInput != valueData) {

            setmessageErro(`El valor ${messageProd} no coincide `)
            seterrAprobar(true)
            setTimeout(() => {
                seterrAprobar(false)
            }, 4000);

        } else {
            aprovateSolicitud(dispatch, request)
                .then((response) => {
                    if (response.codigoRespuesta === "0000") {
                        setisOpen(true)
                        setmessage("la solicitud fue notificada con éxito.")

                        setTimeout(() => {
                            history.push(`/miSolicitud/`)
                        }, 3000);
                    }
                })
        }
    }

    const Rechazar = (motivo, id) => {

        const data = {

            nota: motivo

        }

        sentRechazo(dispatch, data, id)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setisOpen(true)
                    setmessage("Solicitud Notificada con Éxito")

                    setTimeout(() => {
                        history.push(`/miSolicitud/`)
                    }, 3000);
                }
            })
    }

    const getParametersList = (page, size, filter) => {
        getParameters(dispatch, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let data = response.content;
                    let dataFiltro = [];

                    data.forEach(item => {

                        item.key === "MOTIVO_RECHAZO" && dataFiltro.push(item);
                    });

                    setparamRechazo(dataFiltro)
                } else {
                  
                }
            })
    };


    const onFileUploadFacture = async (event, type, filename) => {

        type = type === null || type === undefined ? "" : type;
    
        setshowLoad(true);
        const file = event.target.files[0];
        

        if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "application/pdf") {

            const formData = new FormData();
            let doc = Data.datosPersona.documentNumber;
            if (typeof doc === "string") {
                doc = doc.includes(".") ? doc.split(".") : doc.split(",");
                doc = doc.join("");
                doc = parseInt(doc, 10);
            }
            //this.setState({ [loading]: true });
            setshowLoad(true);
            // const name = file.name.replace(' ', '-');
            let ramdom = Math.floor((Math.round(Math.random() * (999 - 100) + 100))); //Ramdom 3 dijistos
            const lastDot = file.name === undefined ? file.name = "" : file.name.lastIndexOf(".");
            const ext = file.name.substring(lastDot + 1);
            formData.append("file", file, doc + "_" + filename + "." + ext);
          
            //formData.append("file", file, doc + "_" + filename);
            let msg = "";
            if (file.size < 10485760 && doc !== "") {
                let url = `${process.env.REACT_APP_FILES}?doc=Usuario/${doc}`;
                await fetch(url, {
                    // content-type header should not be specified!
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((response) => {
                        
                        if (response.respuesta) {
                            //this.setState({ [loading]: false, [type]: response.mensaje });{
                            if (type === "factura") {
                                setMessageFile("Documento cargado correctamente")
                                let nameFile = file.name;
                                let nameFrontFile = nameFile.length >= 40 ? nameFile.slice(-30) : nameFile;
                                
                                setfacturaMessage(nameFrontFile)
                                setfileUpload(true)
                                setFacturaFile(response.mensaje)
                                setTimeout(() => {
                                    setfileUpload(false)
                                }, 2000)
                                setshowLoad(false);
                                setfileName(response.mensaje)
                            } else {
                               
                            }
                        }
                        else {
                           
                            setshowLoad(false);
                            setfileName("")
                        }
                    })
                    .catch((error) => {
                       
                        msg = "Error";
                        setMessageFile("Error cargando documento")
                        setfileUploadErr(true)
                        setTimeout(() => {
                            setfileUploadErr(false)
                        }, 2000)
                        setshowLoad(false);
                        setfileName("")
                    });
            } else {

                setMessageFile("El documento supera el maximo de 10 MB permitidas")
                setfileUploadErr(true)
                setTimeout(() => {
                    setfileUploadErr(false)
                }, 2000)
                setshowLoad(false);
                setfileName("")
            }
        } else {

            setshowLoad(false);
            event.target.value = null;
            setMessageFile("Unicamente archivos .pdf, .png, .jpg, .jpeg")
            setfileUploadErr(true)
            setTimeout(() => {
                setfileUploadErr(false)
            }, 2000)
            setfileName("")
        }
    };


    return (

        <div className={classes.div}>
            <ModalGeneric
                isOpen={isOpen}
                setopenMondal={setisOpen}
                message={message}
            />
            <Grid item xs={12} >
                <MigaPan namePage="Mis Clientes " to="/miSolicitud/">
                    Mis Clientes
                </MigaPan>
            </Grid>
            <br />
            <Grid container spacing={1} alignItems="flex-end" textAlign="center">
                <Grid item xs={12}>
                    <Paper className={classes.paper} >
                        <Box
                            sx={{
                                p: 5,
                                bgcolor: 'background.default',
                                display: 'grid',
                                // gridTemplateColumns: { md: '1fr 1fr' },
                                gap: 5,
                            }}
                        >
                            {idTipoSoli != 5 ? (

                                <TextField
                                    id=""
                                    label="Valor Abono"
                                    fullWidth
                                    variant="outlined"
                                    value={valueAbono === "0" ? "" : valueAbono}
                                    onChange={((e) => {
                                        e.target.value = formatNumberMoney(e.target.value);
                                        setvalueAbono(e.target.value);
                                    })}


                                />

                            ) : (

                                <TextField
                                    id=""
                                    label="Valor Producto"
                                    fullWidth
                                    variant="outlined"
                                    value={valueProducto === "0" ? "" : valueProducto}
                                    onChange={((e) => {
                                        e.target.value = formatNumberMoney(e.target.value);
                                        setvalueProducto(e.target.value)
                                    })}

                                />

                            )}

                            <Box>
                                <br />
                                <br />
                                <Paper className={classes.paperPhoto}>
                                    <img
                                        className={classes.img}
                                        src={foto}
                                        loading="lazy"

                                    />
                                </Paper>
                                <br />
                                <br />
                            </Box >
                            <div className={classes.btnFactura} >
                                <label htmlFor="factura">
                                    <input
                                        style={{ display: "none" }}
                                        id="factura"
                                        name="factura"
                                        type="file"
                                        required
                                        accept="image/jpeg,image/png,image/jpg,application/pdf"
                                        onChange={((e) => {
                                            onFileUploadFacture(
                                                e,
                                                "factura",
                                                Data.id + "_factura"
                                            )
                                        })}
                                    />
                                    <Fab
                                        color="primary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended"
                                    >
                                        <CreateNewFolder />&nbsp;&nbsp;{facturaMessage}&nbsp;&nbsp;
                                    </Fab>
                                </label>
                            </div>
                            {showLoad &&
                                (

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            margin: '0 auto',
                                            '& > :not(style)': {
                                                m: 1,
                                                width: 50,
                                                height: 50,
                                            },
                                        }}
                                    >
                                        <ReactLoading
                                            type={"bars"}
                                            color={"#000"}
                                            height={50}
                                            width={50}
                                        />
                                    </Box>

                                )
                            }
                            <br />
                            {fileUploadErr && <Alert severity="error">{messgeFile}</Alert>}
                            {fileUpload && <Alert severity="success">{messgeFile}</Alert>}
                            <Box sx={{ minWidth: 120 }}>
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-customized-select-label">Motivo de Devolución</InputLabel>
                                    <Select
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={valueMotivo}
                                        label="Motivo de Devolución"
                                        onChange={((e) => { setMotivo(e.target.value) })}
                                    >
                                        <MenuItem value="">
                                            <em>Selecciona un motivo</em>
                                        </MenuItem>
                                        {
                                            paramRechazo.map((item) => (
                                                <MenuItem value={item.descripcion}>{item.nombre}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    p: 5,
                                    bgcolor: 'background.default',
                                    display: 'grid',
                                    gridTemplateColumns: { md: '1fr 1fr' },
                                    gap: 5,
                                }}
                            >
                                <br />
                                <br />
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    alignContent="stretch"
                                    wrap="nowrap"

                                >
                                     <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            color="secondary"
                                            startIcon={<Cancel />}
                                            onClick={((e) => { Rechazar(valueMotivo, Data.id) })}
                                            disabled={disableDen}
                                        >
                                            Reenviar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            startIcon={<CheckCircle />}
                                            disabled={disabledApr}
                                            onClick={((e) => { AprobarSolicitud(Data.id) })}
                                        >
                                            Aprobar
                                        </Button>
                                    </Grid>
                                   
                                </Grid>
                            </Box>
                        </Box>
                        <br />
                        <br />
                        {errAprobar && <Alert severity="error">{messageErro}</Alert>}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )

}
