//React
import React, { useEffect, useRef, useState, FormEvent } from 'react';
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import ReactCodeInput from "react-code-input";
import { store } from 'react-notifications-component';
import MigaPan from '../../app/orussystem/component/MigaPan';
import { useHistory } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { v4 as uuid } from "uuid";

//Redux
import { ID_SUCURSAL, ID_COMERCIO, ID_USER, USER_ROL, ID_SELLER } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icons Material
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { HelpValidate } from '../../user/utils/helpValidate';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../app/orussystem/component/Title';
import {
  Box,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Paper,
  TextField,
  Button,
  IconButton,
  Icon,
  Select,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography
} from '@material-ui/core';


import { SyncDisabled, TramRounded } from '@material-ui/icons';

//Local Component
import { InfoPayment } from '../recaudos/InfoPayment';

//importar api
import {
  getOptCommerceCredictSMS,
  getpaymentcommerce,
  calloptcommerce,
  getSucursalByIdCommerce,
  geListSucuralById
} from '../domain/api/PrestapolisApi'

//My style
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #2c8aaf',
    width: 400,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 3),
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  div: {
    margin: '0 auto',
    minWidth: '40%',
  },
  textField: {
    width: '75%',

  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    overflow: 'auto',
    margin: '0 auto',
    flexDirection: 'column',
  },
  paperPhoto: {
    padding: theme.spacing(0),
    display: 'flex',
    margin: '0 auto',
    maxWidth: '500px',
    overflow: 'auto',
    flexDirection: 'column',
  },
  btnFactura: {
    margin: '0px !important',
    textAlign: 'center',
    justifycontent: 'center'
  },
  button: {
    color: '#ff0000 !important',
  },
  img: {
    maxWidth: '1000px',
  },
  inputPerson: {
    marginTop: '15px'
  },
  buttonpadding: {
    padding: '1000px',
  },

}));



export default function ModalRegistroPago({ isOpen, setopenMondal, onSubmit, setuserDni, clearModal, Clear, celular, valor_prod, listSucursal }) {

  const classes = useStyles();
  const [isLoadUser, setisLoadUser] = useState(false)
  const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
  const [cedula_vendedor, setCedula_vendedor] = useState("");
  const [idSucursalSelect, setidSucursalSelect] = useState("");
  const [optRef, setOptRef] = useState("");
  const [state, setState] = useState("");
  const [sucursal, setSucursal] = useState("")
  const dispatch = useDispatch();

  const constRes = 20;
  const [timer, setTimer] = useState(constRes);
  const [isDesableSMS, setDesableSMS] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [filterSucursal, setfilterSucursal] = useState([]);
  let rol = localStorage.getItem(USER_ROL);
  let idSucursal = localStorage.getItem(ID_SUCURSAL);
  let idComerce = localStorage.getItem(ID_COMERCIO);
  let idSeller = localStorage.getItem(ID_SELLER);
  //PRUEBA
  const [text, enableButton] = useState("");

  const handleTextChange = (event) => {
    enableButton(event.target.value);
  };
  const handleSubmit2 = (event) => {
    event.preventDefault();
  };



  const location = useLocation();
  const cr = location.state.credict;
  const history = useHistory();

  //Filter Estados    
  function getDropdownButton({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: Seleccionar todos`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} Seleccionado`;
    }
  }


  const SendForm = (data) => {
    onSubmit(data)
    ClearForm();
  }

  const ExitModal = () => {
    setopenMondal(false);

    ClearForm();
  }

  const handleChange = opt => {
    opt.replace(" ", /^[a-zA-Z ]*$/)

    setOptRef(opt);
  };

  const handleChangeText = opt => {
    opt.replace(" ", /^[a-zA-Z ]*$/)

    setCedula_vendedor(opt);
  };

  const onCerrar = () => {
    clearInterval(intervalRef.current)
    setopenMondal(false)

  }

  let intervalRef = useRef();

  const sendReenvio = (initConst) => {

    if (!initConst) {
      clearInterval(intervalRef.current)
      return;
    }
    setDesableSMS(true);
    setTimer(constRes)
    intervalRef.current = setInterval(() => {
      setTimer(timer => timer - 1);
    }, 1000);

  }

  useEffect(() => {
    if (timer <= 0) {
      setDesableSMS(false);
      clearInterval(intervalRef.current)
    }
  }, [timer])

  useEffect(() => {
    ClearForm();
    setDesableSMS(false);

  }, [])

  useEffect(() => {
    ClearForm();
    setDesableSMS(false);
    sendReenvio(isOpen);

  }, [isOpen])

  const ClearForm = () => {
    setCedula_vendedor("")
    setOptRef("")
  }


  //Metodo para volver a enviar codigo por SMS 
  const getOptCommerceModal = () => {
    setisLoadUser(true);
    let idComerce = localStorage.getItem(ID_COMERCIO)
    getOptCommerceCredictSMS(dispatch, celular, cr.id, valor_prod.replace(".", ""), idComerce, cr.documento, true)
      .then((response) => {
        if (response.codigoRespuesta === "0000") {
          setisLoadUser(false);
        } else {
         
          setisLoadUser(false)
        }

      })
      .catch((error) => {
       
        if (error.status === 401) {
        }
      });

  };


  //Metodo para volver a enviar codigo por LLAMADA
  const postCalloptcommerce = () => {
    setisLoadUser(true);
    let idComerce = localStorage.getItem(ID_COMERCIO);
    let request = {
      idcredito: cr.id,
      vendedor: cedula_vendedor,
      valorpago: valor_prod.replace(".", ""),
      cliente: cr.documento,
      celular: celular,
      idcomercio: idComerce,
    }
    calloptcommerce(dispatch, request)
      .then((response) => {
        if (response.codigoRespuesta === "0000") {
          setisLoadUser(false);
        } else {
       
          setisLoadUser(false)
        }
      })
      .catch((err) => {
       
      })
  }

  //Metodo para enviar los datos 
  const getPaymentcommerceEnviar = () => {
    let idComerce = localStorage.getItem(ID_COMERCIO);
    let id_usuario = localStorage.getItem(ID_USER);
    let sucursal = localStorage.getItem(ID_SUCURSAL);

    sucursal = sucursal.includes("-") ? sucursal.split("-")[0] : sucursal;

    setisLoadUser(true);
    let request = {
      opt: optRef,
      idcredito: cr.id,
      vendedor: cedula_vendedor,
      valorpago: valor_prod.replace(".", ""),
      cliente: cr.documento,
      celular: celular,
      idcomercio: parseInt(idComerce),
      id_usuario: parseInt(id_usuario),
      sucursal: parseInt(sucursal),
      referencia: "pago comercio",
      medioPago: {
        id: 25
      },
      sucursal: sucursal
    }
    getpaymentcommerce(dispatch, request)
      .then((response) => {
        if (response.codigoRespuesta === "0000") {
          setisLoadUser(false);
          store.addNotification({
            title: "El pago ha sido exitoso!",
            message: "El pago ha sido exitoso!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 6000,
              // onScreen: true
            }
          });
          ExitModal();
          history.push({
            pathname: `/recaudos/historico/`,
            search: '',
          })
        } else {
          store.addNotification({
            title: "Pago Exitoso, será procesado en Breve!",
            message: response.mensaje.replace("Se envia SMS fallo pago", "¡Exitoso!"),
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 7500,
            }
          });
          ExitModal();
          history.push({
            pathname: `/recaudos/historico/`,
            search: '',
          })

          setisLoadUser(false)
        }
      })
      .catch((err) => {
     
        store.addNotification({
          title: "Error",
          message: "Error enviando el pago, por favor comunicarce con soporte@prestapolils.com",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 9500,
          }
        });
        setisLoadUser(false)
      })
  }


  //Validacion de campos 
  const validateCampos = () => {
    getPaymentcommerceEnviar();
  }




  return (
    <div>
      <Modal
        open={isOpen}
        onClose={(e) => {
          ExitModal()
        }}
        className={classes.modal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paperModal}>
            <Grid container spacing={1} alignItems="center" textAlign="center">
              <Grid item xs={12}>
                <form autocomplete="off" onSubmit={handleSubmit2}>

                  <Grid container spacing={1} alignItems="center" className={classes.inputPerson} >
                    <Grid
                      container
                      justify="center"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="nowrap"
                    >
                      <Typography style={{ fontWeight: 700, fontSize: 25 }}>
                        <p>Registro de pago</p>
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      alignItems="flex-start"
                      alignContent="stretch"
                      wrap="nowrap"
                    >
                      <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                        {"El código de aprobación se envío al pagador al número: " + celular}
                      </Typography>
                    </Grid>
                    <Grid container justify="center">
                      <ReactCodeInput style={{ marginTop: '10px' }}
                        type="optRef"
                        onChange={handleChange}
                        autocomplete="off"
                        autoFocus={false}
                        fields={6}
                        id="optRef"
                        name="optRef"
                        filterChars={['-', '.', '.', "a", "s", "z", "q"]}
                        value={optRef}
                        defaultValue={optRef}
                      />
                    </Grid>
                  </Grid>



                  <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '30px' }}>
                    <FormControl fullWidth variant="outlined" >
                      <InputLabel htmlFor="outlined-adornment-amount">Cedula vendedor*</InputLabel>
                      <OutlinedInput
                        startAdornment={<InputAdornment position="start"><AccountBoxIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                        labelWidth={140}
                        fullWidth
                        autocomplete="off"
                        type='cedula_vendedor'
                        onChange={handleTextChange}
                        id="cedula_vendedor"
                        name="cedula_vendedor"
                        value={cedula_vendedor.replace(".", "")}
                        defaultValue={cedula_vendedor}
                        {...HelpValidate().validateNumber(register('cedula_vendedor', {
                          required: "La cedula es requerida.",
                          minLength: {
                            value: 20,
                            message: 'La cedula debe tener minimo 8 caracteres'
                          },
                        }), 7, 16, setError, clearErrors, setCedula_vendedor)}
                        error={!!errors.cedula_vendedor}
                        helperText={errors.cedula_vendedor?.message}
                      />
                    </FormControl>
                  </Grid>

                  <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '30px' }}>
                    <FormControl fullWidth variant="outlined" >
                      <InputLabel htmlFor="outlined-adornment-amount">Sucursal *</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="sucursal"
                        name="sucursal"
                        autocomplete="off"
                        defaultValue={sucursal}
                        value={sucursal}
                        
                        {...HelpValidate().validateSelectValue(register("sucursal", {
                          required: "Seleccione una sucursal",
                        }), setSucursal)}
                        error={!!errors.rol}
                        helperText={errors.rol?.message}
                        label="sucursal"
                        startAdornment={<InputAdornment position="start">-</InputAdornment>}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {listSucursal.map((item) =>
                          <MenuItem value={item.id}>{item.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid container spacing={1} alignItems="center" className={classes.inputPerson} >
                    <Grid container justify="center" >
                      <p >
                        Si no te llega un código al celular te podemos reenviar tu código por SMS o LLAMADA:
                      </p>
                      <p >
                        🕠 00:{timer > 9 ? timer : '0' + timer}
                      </p>

                    </Grid>
                  </Grid>

                  <Grid container xs spacing={2} textAlign="center" justifyContent="space-between">
                    <Grid item xs={6} spacing={1} >
                      <Button
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        className={classes.margin}
                        color="primary"
                        disabled={isDesableSMS}
                        endIcon={<Icon>sms</Icon>}
                        onClick={() => {
                          sendReenvio(true);
                          getOptCommerceModal();
                        }}
                        style={{ textTransform: "none", fontSize: 14 }}
                      >
                        SMS
                      </Button>
                    </Grid>
                    <Grid item xs={6} >
                      <Button
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        className={classes.buttonpadding.margin}
                        endIcon={<Icon>call</Icon>}
                        color="primary"
                        style={{ textTransform: "none", fontSize: 14 }}
                        disabled={isDesableSMS}
                        onClick={() => {
                          sendReenvio(true);
                          postCalloptcommerce();
                        }}
                      >
                        Llamada
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container xs spacing={2} textAlign="center" justifyContent="space-between" >
                    <Grid item xs={6} spacing={1}  >
                      <Button
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        className={classes.margin}
                        color="secondary"
                        onClick={(e) => {
                          onCerrar()
                          setDisable(false)
                        }}
                        style={{ textTransform: "none", fontSize: 14, color: "white" }}
                      >
                        Cerrar
                      </Button>
                    </Grid>
                    <Grid item xs={6} >
                      <Button
                        type="submit"
                        disabled={!cedula_vendedor || !optRef || disable}
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        className={classes.margin}
                        color="primary"
                        style={{ textTransform: "none", fontSize: 14 }}
                        onClick={(e) => {
                          validateCampos();
                          setDisable(true)
                        }}
                      >
                        Enviar pago
                      </Button>
                    </Grid>
                  </Grid>

                </form>

              </Grid>

            </Grid>
          </div>

        </Fade>

      </Modal>
    </div>
  );


}
