import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
//Redux
import { ID_USER, ID_SUCURSAL, ID_COMERCIO, USER_ROL } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icons Material
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from "material-ui-search-bar";
import {
    Paper,
    Grid,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TableContainer,
} from '@material-ui/core';

//Local Component
import Paginator from '../utils/paginator';
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import { getSucursalByIdCommerce, createSucursal, editSucursal } from '../domain/api/PrestapolisApi'
import {
    formatData,
    formatMoney
}
    from '../utils/validation'
import ModalSucursalCreate from '../modals/modalCreateSucursal';
import ModalSucursalEdit from '../modals/modalEditarSucursal';


const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center",
    }
}));

export const Sucursal = () => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isLoadUser, setisLoadUser] = useState(false)
    const [data, setData] = useState([])
    const [totalElements, settotalElements] = useState(0)
    const [totalPage, settotalPage] = useState(0)
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(10)
    const [filter, setFilter] = useState("")

    const [isOpenModalEdit, setisOpenModalEdit] = useState(false)
    const [isOpenModalCreate, setisOpenModalCreate] = useState(false)
    const [dataEdit, setdataEdit] = useState({})

    let idComercio = localStorage.getItem(ID_COMERCIO);
    let rol = localStorage.getItem(USER_ROL);

    const handleRowClick = (id) => {
        rol != "ROLE_COMMERCE_ADMIN"
            ? history.push(`/vendedores/${id}`)
            : history.push(`/vendedores-sucursal/${id}`);
    }






    const GetSucursal = (id, page, size, filter) => {
        setisLoadUser(true);
        
        getSucursalByIdCommerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let totalPages = response.totalPages;
                    let totalElements = response.totalElements;
                    let data = response.content;
                    setData(data);
                    settotalElements(totalElements);
                    settotalPage(totalPages);

                    setisLoadUser(false);
                } else {
             
                    setisLoadUser(false)
                }
            })
            .catch((error) => {
                
                if (error.status === 401) {

                }
            });

    };

    const SetDataAsync = (setState, item) => {
        return new Promise((resolve, reject) => {
            resolve(setState(item));
        })
    }

    const filtrar = (e) => {
        if (e === "") {
            GetSucursal(idComercio, page, size, filter)
        } else {
            GetSucursal(idComercio, page, size, e)
        }
    }

    const EditSucursal = (data) => {
        SetDataAsync(setdataEdit, data).then((response) => {
            setisOpenModalEdit(true)
        })
    }

    //Crear Sucursal
    const OnsubmitCreate = (data, listDepartamento, listMunicipio) => {

        let idDepartamento = parseInt(data.departamento)
        let idMunicipio = parseInt(data.municipio)

        let cityName = []
        listDepartamento.map((item) => {
            if (item.id === idDepartamento)
                cityName.push(
                    item.nombre
                )
        })

        cityName = cityName.toString()

        let MunicipioName = []
        listMunicipio.map((item) => {
            if (item.id === idMunicipio)
                MunicipioName.push(
                    item.nombre
                )
        })

        MunicipioName = MunicipioName.toString()



        let request = {
            estado: data.estado,
            logo:data.logo,
            nombre: data.nameSucursal,
            phone: data.cellPhone,
            types: data.tipo,
            mainbranch: false,
            logo: data.logo,
            enabledsales: true,
            cedula_admin: data.documentAdmin,
            name_admin: data.nameAdmin,
            email: data.email,
            comision: 0,
            vendedoresPermitidos: data.numVendedor,
            comercioBean: {
                id: idComercio
            },
            localizacione: {
                nombre: `${data.nomclatura} ${data.addresTypeNumber} # ${data.addresNumber}`,
                municipio: {
                    id: idMunicipio
                },
                departamento: idDepartamento,
                ciudad: MunicipioName
            }
        }


        // let request = {
        //     comercio: {
        //         id: idComercio,
        //     },
        //     sucursale: {
        //         estado: "A",
        //         nombre: data.nameSucursal,
        //         phone: data.cellPhone,
        //         types: data.tipo,
        //         mainbranch: false,
        //         enabledsales: true,
        //         cedula_admin: data.documentAdmin,
        //         name_admin: data.nameAdmin,
        //         email: data.email,
        //         comision: 0,
        //         vendedoresPermitidos: data.numVendedor
        //     },
        //     localizacione: {
        //         nombre: `${data.nomclatura} ${data.addresTypeNumber} # ${data.addresNumber}`,
        //         municipio: {
        //             id: idMunicipio,
        //         },
        //         ciudad: MunicipioName,
        //         departamento: idDepartamento,
        //     },
        //     user: {
        //         password: data.documentAdmin,
        //         username: data.nameAdmin,
        //         email: data.email,
        //         name: data.nameAdmin,
        //         rol: "ROLE_BRANCH_ADMIN",
        //     },
        // };

        CreateSucursales(request)
    }

    const CreateSucursales = (request) => {
        createSucursal(dispatch, request)
            .then((response) => {
                if (response.respuesta) {
                    store.addNotification({
                        title: "Exito!",
                        message: "Sucursal creada con exito",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3500,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        GetSucursal(idComercio, 0, size, "")
                    }, 1800);

                } else if (response.codigoRespuesta === "DB04") {
                    store.addNotification({
                        title: "Error",
                        message: "¡Ups, excedió la cantidad de sucursales permitidas ",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalCreate(false)
                        GetSucursal(idComercio, 0, size, "")
                    }, 3500);
                } else {
                    store.addNotification({
                        title: "Error",
                        message: "¡Ups, no fue posible crear la sucursal revisa los datos por favor!",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        // setisOpenModalCreate(false)
                        GetSucursal(idComercio, 0, size, "")
                    }, 3500);

                }
            })
            .catch((err) => {
             
            })
    }

    //Editar Sucursal
    const OnsubmitEdit = (data) => {

        let request = {
            id: dataEdit.id,
            estado: data.estado,
            nombre: data.nameSucursal,
            phone: data.cellPhone,
            logo:data.logo,
            types: data.tipo,
            mainbranch: false,
            enabledsales: true,
            cedula_admin: data.documentAdmin,
            name_admin: data.nameAdmin,
            email: dataEdit.email,
            creationdate: dataEdit.creationdate,
            vendedoresPermitidos: data.numVendedor,
        };


        EditSucursales(request)
    }

    const EditSucursales = (request) => {
        editSucursal(dispatch, request)
            .then((response) => {
                if (response.respuesta) {
                    store.addNotification({
                        title: "Exito!",
                        message: "Sucursal Modificada con exito",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalEdit(false)
                        GetSucursal(idComercio, 0, size, "")
                    }, 3500);

                } else {
                    store.addNotification({
                        title: "Error",
                        message: "¡Ups, no fue posible editar la sucursal revisa los datos por favor!",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setTimeout(() => {
                        setisOpenModalEdit(false)
                        GetSucursal(idComercio, 0, size, "")
                    }, 3500);

                }
            })
            .catch((err) => {
              
            })
    }


    useEffect(async () => {
        GetSucursal(idComercio, page, size, filter)
    }, [page], [totalElements]);


    return (
        <>
            {isOpenModalCreate && <ModalSucursalCreate
                isOpen={isOpenModalCreate}
                setopenMondal={setisOpenModalCreate}
                onSubmit={OnsubmitCreate}
                data={data}
                
            />}
            {isOpenModalEdit && <ModalSucursalEdit
                isOpen={isOpenModalEdit}
                setopenMondal={setisOpenModalEdit}
                onSubmit={OnsubmitEdit}
                data={dataEdit}
            />}

            <Grid item xs={12} >

                <MigaPan namePage="Sucursales" to="/" >
                    Prestápolis
                </MigaPan>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={3} sm={3} md={3} style={{ textAlign: "right" }}>

                </Grid>
                <Grid item xs={12} sm={3} md={3} style={{ textAlign: 'right' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(e) => { GetSucursal(idComercio, 0, size, "") }}
                    >
                        <RotateLeftIcon />
                    </IconButton>

                    <Button
                        variant="contained"
                        style={{color:'#FFFFFF',
                        background:'#2BAEB3'}}
                        size="large"
                        // disabled={row.estadoSolicitud.id != 10? true: false}
                        onClick={(e) => {
                            setisOpenModalCreate(true)
                        }}
                    >
                        Crear Sucursal
                    </Button>

                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <SearchBar
                        value={filter}
                        onChange={(e) => filtrar(e)}
                        onCancelSearch={() => filtrar("")}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >
                            <Title >Sucursales</Title>
                        </Box>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow alignItems="center">
                                        {/* <TableCell align="center">ID Sucursal</TableCell> */}
                                        <TableCell align="center">Fecha de creación</TableCell>
                                        <TableCell align="center">Sucursal</TableCell>
                                        <TableCell align="center" >Ciudad</TableCell>
                                        {/* <TableCell align="center">Representante</TableCell> */}
                                        <TableCell align="center">Número de usuarios</TableCell>
                                        <TableCell align="center">Número de creditos</TableCell>
                                        {/* <TableCell align="center">Comisión</TableCell> */}
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Editar</TableCell>
                                        <TableCell align="center">Usuarios</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoadUser ?
                                        <>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                wrap="nowrap"
                                            >
                                                <ReactLoading
                                                    type={"bars"}
                                                    color={"#000"}
                                                    height={50}
                                                    width={50}
                                                />
                                            </Grid>
                                        </>
                                        : data.map((row, key) => (
                                            <TableRow key={key}>
                                                {/* <TableCell align="center">{`${row.id}`}</TableCell> */}
                                                <TableCell align="center">{row.created === null || row.created === undefined ? "----" : `${formatData(row.created)}`}</TableCell>
                                                <TableCell align="center">{row.nombre === null || row.nombre === undefined ? "----" : `${row.nombre}`}</TableCell>
                                                <TableCell align="center">{row.localizacione.ciudad === null || row.localizacione.ciudad === undefined ? "----" : `${row.localizacione.ciudad}`}</TableCell>
                                                {/* <TableCell align="center">{row.name_admin === null || row.name_admin === undefined ? "----" : `${row.name_admin}`}</TableCell> */}
                                                <TableCell align="center">{
                                                    row.numeroVendedores === null || row.numeroVendedores === undefined
                                                        ? "----"
                                                        : row.vendedoresPermitidos === null || row.vendedoresPermitidos === undefined
                                                            ? `${row.numeroVendedores}`
                                                            : `${row.numeroVendedores}/${row.vendedoresPermitidos}`

                                                }</TableCell>
                                                <TableCell align="center">{row.numeroCreditos === null || row.numeroCreditos === undefined ? "----" : `${row.numeroCreditos}`}</TableCell>
                                                {/* <TableCell align="center">{row.comision === null || row.comision === undefined? "----": `${row.comision}`}</TableCell> */}
                                                <TableCell align="center">{row.estado === null || row.estado === undefined ? "----" : row.estado === "A" ? "Activo" : "Inactivo"}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{color:'#FFFFFF',
                                                        background:'#2BAEB3'}}
                                                        // disabled={row.estadoSolicitud.id != 10? true: false}
                                                        onClick={(e) => {
                                                            EditSucursal(row)
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{color:'#FFFFFF',
                                                        background:'#2BAEB3'}}
                                                        // disabled={row.estadoSolicitud.id != 10? true: false}
                                                        onClick={(e) => {
                                                            handleRowClick(row.id)
                                                        }}
                                                    >
                                                        Usuarios
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page}

                            />
                        </Grid>
                        <br />

                    </Paper>
                </Grid>
            </Grid>
        </>
    )

}