import { CollectionsOutlined } from '@material-ui/icons';
import React from 'react';


export const HelpValidate = () => {

    const validateNumber = (register, max, min, setError, clearErrors, setValue) => {

        let onChange = (e) => {
            e.target.value = writeNumber(e.target.value);
            e.target.value = e.target.value.substring(0, min);
            const expr = /^([0-9])+$/;
        

            clearErrors();
            if (e.target.value.length < max) {
                setError(register.name, {
                    type: "manual",
                    message: `El tamaño minimo es de ${max}`,
                });
            } else {
                clearErrors();
            }

            setValue(e.target.value)

            return register.onChange;
        }


        return {
            ...register,
            onChange
        }


    }

    const validateSelectValue = (register, setvalue) => {

        let onChange = (e) => {
            setvalue(e.target.value)
        }
        return {
            ...register,
            onChange
        }


    }




    const comparePass = (register, max, min, setError, clearErrors, setValue, compareValue) => {

        let onChange = (e) => {
            e.target.value = e.target.value.substring(0, min);

            clearErrors();
            if (e.target.value.length < max) {
                setError(register.name, {
                    type: "manual",
                    message: `El tamaño minimo es de ${max}`,
                });
            }

            if (e.target.value != compareValue) {
                setError(register.name, {
                    type: "manual",
                    message: `La contraseña no coincide`,
                });
            }

            setValue(e.target.value)

            return register.onChange;
        }


        return {
            ...register,
            onChange
        }


    }

    const validatePass = (register, max, min, setError, clearErrors, setValue) => {

        let onChange = (e) => {
            e.target.value = e.target.value.substring(0, min);

            clearErrors();
            if (e.target.value.length < max) {
                setError(register.name, {
                    type: "manual",
                    message: `El tamaño minimo es de ${max}`,
                });
            }

            setValue(e.target.value)

            return register.onChange;
        }


        return {
            ...register,
            onChange
        }


    }

    const validateText = (register, setValue) => {

        let onChange = (e) => {
            e.target.value = writeText(e.target.value);

            setValue(e.target.value)
            return register.onChange;
        }


        return {
            ...register,
            onChange
        }
    }


    
    const NotvalidateText = (register, setValue)=>{

        let onChange = (e) => {
            
            setValue(e.target.value)
            return register.onChange;
        }


        return {
            ...register,
            onChange
        }
    }

    const validateEmail = (register, setValue) => {

        let onChange = (e) => {
            e.target.value = FormatEmail(e.target.value)

            setValue(e.target.value)
            return register.onChange;
        }


        return {
            ...register,
            onChange
        }
    }


    const limitLengthNumber = (register, max, min, setError, clearErrors) => {

        let onChange = (e) => {
            e.target.value = writeNumber(e.target.value);
            e.target.value = e.target.value.substring(0, min);

            clearErrors();
            if (e.target.value.length < max) {
                setError(register.name, {
                    type: "manual",
                    message: `El tamaño minimo es de ${max}`,
                });
            }


            return register.onChange;
        }


        return {
            ...register,
            onChange
        }

    }

    const FormatEmail = (email) => {

        const expr = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
        return expr.test(email);

    };


    const FormatEmailSigin = (register, setError, clearErrors) => {


        let onChange = (e) => {

            clearErrors();

            if (!FormatEmail(e.target.value)) {
                setError(register.name, {
                    type: "manual",
                    message: `Formato no valido`,
                });
            }

            return register.onChange;

        }

        return {
            ...register,
            onChange
        }
    }

    const formatEmailGeneric = (register, setError, clearErrors, setEmail) => {

        let onChange = (e) => {

            clearErrors();

            if (!FormatEmail(e.target.value)) {
                setError(register.name, {
                    type: "manual",
                    message: `Formato no valido`,
                });
                setEmail(e.target.value)
            } else {
                clearErrors();
                setEmail(e.target.value)
            }

            return register.onChange;

        }

        return {
            ...register,
            onChange
        }

    }

    const limintCodeNumber = (register, max, min, setError, clearErrors, ref, refName, focusInput) => {

        let onChange = (e) => {
            e.target.value = writeNumber(e.target.value);
            e.target.value = e.target.value.substring(0, min);

            clearErrors();
            if (e.target.value.length < max) {
                setError(register.name, {
                    type: "manual",
                    message: `El tamaño minimo es de ${max}`,
                });
            }

            if (e.target.value != '') {
                focusInput(refName)
            }
            return register.onChange;
        }


        return {
            ...register,
            onChange
        }


    }

    const limitLengthText = (register) => {

        let onChange = (e) => {
            e.target.value = writeText(e.target.value);


            return register.onChange;
        }


        return {
            ...register,
            onChange
        }

    }

    const formatNumber = (register, setbutton) => {

        let onChange = (e) => {

            let value = e.target.value.toString().replaceAll(".", "").replaceAll(",", "");
            value = writeNumber(value);
            value = new Intl.NumberFormat(("ban", "id")).format(value);
            e.target.value = value;
            setbutton(false);

            return register.onChange;



        }


        return {
            ...register,
            onChange,

        }

    }

    const formatMoneyNumber = (register, setValue, setButton) => {

        let onChange = (e) => {

            let value = e.target.value.toString().replaceAll(".", "").replaceAll(",", "");
            value = writeNumber(value);
            value = new Intl.NumberFormat(("ban", "id")).format(value);
            e.target.value = value;
            setValue(e.target.value)
            setButton(false)
            return register.onChange;
        }
        
        return {
            ...register,
            onChange,
        }
    }

    const calcAbono = (register, setValue, setButton, setAbono, endeudamiento) => {

        let onChange = (e) => {
            let value = e.target.value.toString().replaceAll(".", "").replaceAll(",", "");
            let abono = value === "" ? 0 : parseInt(value) - endeudamiento
            abono = abono <= 0 ? 80000 : abono;
            value = writeNumber(value);
            abono = new Intl.NumberFormat(("ban", "id")).format(abono);
            value = new Intl.NumberFormat(("ban", "id")).format(value);
            e.target.value = value;
            setValue(e.target.value);
            setButton(false);
            setAbono(abono);
            
            return register.onChange;
        }
        return {
            ...register,
            onChange,
        }
    }

    const calcAbonoCrete = (register, setValue, setButton, setAbono, comerceData) => {

        let onChange = (e) => {

            let valueAbono = ""
            let value = e.target.value.toString().replaceAll(".", "").replaceAll(",", "");
            value = writeNumber(value);
            valueAbono = value * (comerceData.baseDivisorAbono / 100);
            valueAbono = Math.round(valueAbono / 100) * 100;
            valueAbono = Math.trunc(valueAbono);
            valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");
            valueAbono = writeNumber(valueAbono);
            valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono);
            setAbono(valueAbono);
            setButton(false);
            value = new Intl.NumberFormat(("ban", "id")).format(value);
            e.target.value = value;
            setValue(e.target.value);
        }
        return {
            ...register,
            onChange,
        }
    }

    const isDateMaxEdad = (register, setError, clearErrors, message, daysNumber, setBirt) => {

        let onChange = (e) => {

            const fecha1 = new Date(e.target.value);
            const fecha2 = new Date();

            const difference = Math.abs(fecha2 - fecha1);
            const days = difference / (1000 * 3600 * 24)

            clearErrors();
            if (Math.trunc(days) < daysNumber) {
                setError(register.name, {
                    type: "manual",
                    message: `${message}`,
                });
            } else {
                clearErrors();
                setBirt(fecha1);
            }


            return register.onChange;
        }


        return {
            ...register,
            onChange,

        }

    }

    const isData = (register, setError, clearErrors, message, setDate) => {

        let onChange = (e) => {

            // yyyy-MM-dd".
            const date = new Date(e.target.value);
            const dateCurrent = new Date();

            clearErrors();
            // if (date <= dateCurrent) {
            //     setError(register.name, {
            //         type: "manual",
            //         message: `${message}`,
            //     });
            //     setDate(e.target.value);
            // } else {
            //     clearErrors();
            //     setDate(e.target.value);
            // }

            setDate(e.target.value);


            return register.onChange;
        }


        return {
            ...register,
            onChange,

        }
    }


    const isDateExp = (register, setError, clearErrors, message, birthDate) => {

        let onChange = (e) => {

            const fecha1 = new Date(e.target.value);
            const fecha2 = new Date();
            const fechaNa = new Date(birthDate);

            clearErrors();
            if (fecha1 > fecha2 || fecha1.getFullYear() < fechaNa.getFullYear() + 18) {
                setError(register.name, {
                    type: "manual",
                    message: `${message}`,
                });
            } else {
                clearErrors();
            }


            return register.onChange;
        }


        return {
            ...register,
            onChange,

        }

    }

    const validateMultiSelect = (register, handleChange, filter) => {

        let onChange = (event) => {
            if (event === null || event.length <= 5) {
                filter(event);
            }
        }

        let onInputChange = (event) => {
            if (event === null || event.length <= 5) {
                handleChange(event)
            } else {
                alert("solo puedes agregar 5")
            }
        }

        return {
            ...register,
            onChange,
            onInputChange,

        }


    }

    const writeNumber = (text) => {
        if (!isNaN(text)) {
            return text;
        }
        return text.substring(0, text.length - 1);

    }


    const writeText = (text) => {
        if (isNaN(text)) {
            return text;
        }
        return text.substring(0, text.length - 1);

    }

    
    const formatNumberAbono = (register, setValueAbono, comerceData, valorAbonoMinimo, setError, clearErrors, setminAbono, setbutton)  =>{

        let onChange = (e) => {

            let tipoForm = comerceData.tipoFormulario === undefined? "NO_TIPO": comerceData.tipoFormulario;

            if (tipoForm === "ABONO_MINIMO_SUGERIDO"){
                let value = e.target.value.toString().replaceAll(".","").replaceAll(",","");
                value = writeNumber(value);
                console.log(value)
                value = new Intl.NumberFormat(("ban", "id")).format(value); 
                e.target.value = value;
                setValueAbono(value);
            }

            if (tipoForm === "ABONO_MINIMO_OBLIGATORIO"){
                let value = e.target.value.toString().replaceAll(".","").replaceAll(",","");
                value = writeNumber(value);
                
                if(value < valorAbonoMinimo){
                    setError(register.name, {
                        type: "manual",
                        message: `El valor minimo es ${valorAbonoMinimo}`,
                    });
                    setminAbono(false)
                }
                else{
                    setminAbono(true)
                    clearErrors();
                }
    
                value = new Intl.NumberFormat(("ban", "id")).format(value); 
                e.target.value = value;
                setValueAbono(value);
            }

            setbutton(false)

            return register.onChange;

            

        }


        return {
            ...register,
            onChange,
            
        }

    }





    return {
        limitLengthNumber,
        limitLengthText,
        formatNumber,
        isDateMaxEdad,
        isDateExp,
        isData,
        writeNumber,
        writeText,
        validateMultiSelect,
        limintCodeNumber,
        FormatEmail,
        FormatEmailSigin,
        formatEmailGeneric,
        validateNumber,
        validateText,
        validateEmail,
        validatePass,
        comparePass,
        formatMoneyNumber,
        validateSelectValue,
        calcAbono,
        calcAbonoCrete,
        NotvalidateText,
        formatNumberAbono
    };

}