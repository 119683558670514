//React 
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

//Redux
import { ID_USER, ID_SUCURSAL, ID_COMERCIO } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icons Material
import {
    Visibility,
} from '@material-ui/icons';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';


//Components Material
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from "material-ui-search-bar";
import {
    Paper,
    Grid,
    Button,
    Card,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    CardContent,
    CardMedia,
    CardActionArea,
    TextField
} from '@material-ui/core';

//Local Component
import {
    getSolicitudesByMulti,
    getSolicitudesByDenied,
    getSolicitudesByProcess,
    getSucursalByIdCommerce,
    getVendedoresSucursal
} from '../domain/api/PrestapolisApi'
import Paginator from '../utils/paginator'
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import {
    formatData,
    formatMoney
} from '../utils/validation'


const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    search: {
        borderRadius: "5px",
        height: "10px",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 1px 1px rgb(0 0 0 / 8%)"
    },
    content: {
        flex: '1 0 auto',
    },
    Data: {
        backgroundColor: "#ffffff"
    }
}));


export const ListSolicitudesCommerce = ({}) => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(10)
    const [filter, setFilter] = useState("")
    const [isLoadUser, setisLoadUser] = useState(false)
    const [totalElements, settotalElements] = useState(0)
    const [service, setService] = useState(0)
    //Lista de sucursales
    const [listSucursal, setlistSucursal] = useState([])
    //Array generado por el componente "Filter Sucursal"
    const [filterSucursal, setfilterSucursal] = useState([])
    //Id para filtrar vendedores
    const [idSucursalSelect, setidSucursalSelect] = useState("")

    //Lista de vendedores
    const [listVendedor, setlistVendedor] = useState([])
    //Array generado por el componente "Filter Vendedor"
    const [filterVendedor, setfilterVendedor] = useState([])
    //id para filtrar en servicio final
    const [idVnededorSelect, setidVnededorSelect] = useState("")

    //Filtro fecha inicial
    const [fechaIni, setfechaIni] = useState("")
    //Filtro fecha final 
    const [fechaFin, setfehcaFin] = useState("")
    

    let idUser = localStorage.getItem(ID_USER);
    let idSucursal = localStorage.getItem(ID_SUCURSAL);
    let idComercio = localStorage.getItem(ID_COMERCIO);
    const styleSearch = classes.search
    const styleData = classes.Data

    const GetListSolicitud = (id, page, size, id_sucursal, id_vendedor, estado, fecha_ini, fecha_fin) => {
        setisLoadUser(true)
        getSolicitudesByMulti(dispatch, id, page, size, id_sucursal, id_vendedor, estado, fecha_ini, fecha_fin)
            .then((response) => {
                if (response.code === "0000") {
                    setData(response.content)
                    settotalElements(response.totalElements)
                    setisLoadUser(false)
                }

            })
            .catch((err) => {
              
            })
    }
   
    const GetSolicitudesDenied = (id, page, size, filter, filter2, fecha_ini, fecha_fin) => {
        setisLoadUser(true)
        getSolicitudesByDenied(dispatch, id, page, size, filter, filter2, fecha_ini, fecha_fin)
            .then((response) => {
                setData(response.content)
                setisLoadUser(false)
                settotalElements(response.totalElements)
            })
            .catch((err) => {
                
            })
    }

    const GetSolicitudesProcess = (id, page, size, filter, filter2, fecha_ini, fecha_fin) => {
        setisLoadUser(true)
        getSolicitudesByProcess(dispatch, id, page, size, filter, filter2, fecha_ini, fecha_fin)
            .then((response) => {
                setData(response.content)
                setisLoadUser(false)
                settotalElements(response.totalElements)
            })
            .catch((err) => {
              
            })
    }

    
    const GetSucursales = (id, page, size, filter) =>{
        
        getSucursalByIdCommerce(dispatch, id, page, size, filter)
        .then((response)=>{
            if(response.code === "0000"){
                let data = response.content;
                let lista = [];
                data.map((item) => {
                    lista.push({
                        id: item.id,
                        value: item.id,
                        label: item.nombre,
                    });
                });

                setlistSucursal(lista)
    
            }
        })
        .catch((err)=>{
            
        })
    }

    const ServiceSelect = () => {
        switch (service) {
            case 0:
                return (
                    GetListSolicitud(idComercio, page, size, idSucursalSelect, idVnededorSelect, "", fechaIni, fechaFin)
                )
            case 1:
                return (
                    GetSolicitudesDenied(idComercio, page, size, "", "", fechaIni, fechaFin)
                )
            case 2:
                return (
                    GetSolicitudesProcess(idComercio, page, size, "", "", fechaIni, fechaFin)
                )
            default:
                break;
        }

    }

    const getVendedor = (id, page, size, filter) =>{
        getVendedoresSucursal(dispatch, id, page, size, filter)
        .then((response) => {
            if (response.code === "0000") {
                let data = response.content;
                let lista = [];
                data.map((item) => {
                    lista.push({
                        id: item.id,
                        value: item.id,
                        label: item.nombrescompletos,
                    });
                });

                setlistVendedor(lista)
            } else {
               
            }
            
        })
        .catch((error) => {
            
            if (error.status === 401) {
                
            }
        });
    };

    

    //Filter Estados    
    function getDropdownButton ({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
        return `${placeholderButtonLabel}: Seleccionar todos`;
        } else {
        return `${placeholderButtonLabel}: ${value.length} Seleccionado`;
        }
    }

    //Onchage filter
    function onChange (value, event){
        if (event.action === "select-option" && event.option.value === "*") {
            if(event.name === "sucursal-filter"){
                let idString = []
                listSucursal.map((item)=>{
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                SetDataAsync(setidSucursalSelect, idString).then((response)=>{
                    getVendedor(idString, 0, 20, "")
                })

            }else if(event.name === "vendedor-filter"){
                let idString = []
                listVendedor.map((item)=>{
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setidVnededorSelect(idString)
            }
            this.setState(this.options);
        } else if (
        event.action === "deselect-option" &&
        event.option.value === "*"
        ) {
            if(event.name === "sucursal-filter"){
                setidSucursalSelect("")
            }else if(event.name === "vendedor-filter"){
                setidVnededorSelect("")
            }
            this.setState([]);
        } else if (event.action === "deselect-option") {
            if(event.name === "sucursal-filter"){
                let idString = []
                let arrayFilter = value.filter((o) => o.value !== "*")
                arrayFilter.map((item)=>{
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                SetDataAsync(setidSucursalSelect, idString).then((response)=>{
                    getVendedor(idString, 0, 20, "")
                })

            }else if(event.name === "vendedor-filter"){
                let idString = []
                let arrayFilter = value.filter((o) => o.value !== "*")
                arrayFilter.map((item)=>{
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setidVnededorSelect(idString)
            }
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            if(event.name === "sucursal-filter"){
                let idString = []
                listSucursal.map((item)=>{
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                SetDataAsync(setidSucursalSelect, idString).then((response)=>{
                    getVendedor(idString, 0, 20, "")
                })

            }else if(event.name === "vendedor-filter"){
                let idString = []
                listVendedor.map((item)=>{
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setidVnededorSelect(idString)
            }
            this.setState(this.options);
        } else {
            if(event.name === "sucursal-filter"){

                let select = [];
                let stringSelect=""
                value.map((item)=>{
                    select.push(
                        item.id
                    )
                })

                stringSelect  = select.toString();
                SetDataAsync(setidSucursalSelect, stringSelect).then((response)=>{
                    getVendedor(stringSelect, 0, 20, "")
                })

            }else if(event.name === "vendedor-filter"){

                let select = [];
                let stringSelect=""
                value.map((item)=>{
                    select.push(
                        item.id
                    )
                })

                stringSelect  = select.toString();
                // setFilterEstates(stringSelect)
                SetDataAsync(setidVnededorSelect, stringSelect)

            }


            this.setState(value);
        }
    }


    const SetDataAsync = (setState, item) => {
        return new Promise((resolve, reject) => {
            resolve(setState(item));
        })
    }

    const NextFilter = (num) => {
        SetDataAsync(setService, num).then((response) => {
            ServiceSelect();
        })
    }



    const ReloadComponent = () =>{
        GetListSolicitud(idComercio, page, size, "", "", "", "", "")
        setidSucursalSelect("")
        setidVnededorSelect("")
        setfilterSucursal([])
        setfilterVendedor([])
        setlistVendedor([])
        setfechaIni("")
        setfehcaFin("")

    }

    const filtrar = (e) => {
        if (e === "") {
            ServiceSelect()
        } else {
            ServiceSelect()
        }
    }


    useEffect(() => {
        ServiceSelect()
        GetSucursales(idComercio, 0, 100, "")
    }, [page], [totalElements])

    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Ventas" to="/" >
                    Prestápolis
                </MigaPan>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card >
                        <CardActionArea onClick={(e) => { NextFilter(1) }} >
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    Rechazadas
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Solicitudes Rechazadas
                                </Typography>
                                <div style={{ backgroundColor: "red", height: "2px" }}></div>
                            </CardContent>
                            <CardMedia
                                className={classes.cover}
                                image="/static/images/cards/live-from-space.jpg"
                                title="Live from space album cover"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardActionArea onClick={(e) => { NextFilter(0) }} >
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    En proceso
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Solicitudes en Proceso
                                </Typography>
                                <div style={{ backgroundColor: "orange", height: "2px" }}></div>
                            </CardContent>
                            <CardMedia
                                className={classes.cover}
                                image="/static/images/cards/live-from-space.jpg"
                                title="Live from space album cover"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardActionArea onClick={(e) => { NextFilter(2) }} >
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    Procesada
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Solicitudes Procesadas
                                </Typography>
                                <div style={{ backgroundColor: "green", height: "2px" }}></div>
                            </CardContent>
                            <CardMedia
                                className={classes.cover}
                                image="/static/images/cards/live-from-space.jpg"
                                title="Live from space album cover"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <div style={{ height: "120px" }}></div>
            <Grid container spacing={2}>
                <Grid item xs={3} >
                    <ReactMultiSelectCheckboxes
                    name="sucursal-filter"
                    options={listSucursal}
                    placeholderButtonLabel="Sucursales..."
                    getDropdownButtonLabel={getDropdownButton}
                    value={filterSucursal}
                    onChange={onChange}
                    setState={setfilterSucursal}
                    />
                </Grid>
                <Grid item xs={3} >
                    <ReactMultiSelectCheckboxes
                    name="vendedor-filter"
                    options={listVendedor}
                    placeholderButtonLabel="Vendedores..."
                    getDropdownButtonLabel={getDropdownButton}
                    value={filterVendedor}
                    onChange={onChange}
                    setState={setfilterVendedor}
                    />
                </Grid>
                <Grid item xs={2} >
                    <div style={{ backgroundColor: "#ffffff", padding:"10px"}}>
                        <TextField
                            id="date"
                            label="Fecha Inicial"
                            type="date"
                            fullWidth
                            defaultValue={fechaIni}
                            value={fechaIni}
                            onChange={(e)=>{setfechaIni(e.target.value)}}
                            inputProps={{ className: styleData }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={2} >
                    <div style={{ backgroundColor: "#ffffff", padding:"10px"}}>
                        <TextField
                            id="date"
                            label="Fecha Final"
                            type="date"
                            fullWidth
                            defaultValue={fechaFin}
                            value={fechaFin}
                            onChange={(e)=>{setfehcaFin(e.target.value)}}
                            inputProps={{ className: styleData }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        style={{ backgroundColor: "#fff" }}
                        onClick={(e) => { ReloadComponent() }}
                    >
                        <RotateLeftIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        style={{ backgroundColor: "#fff" }}
                        onClick={(e) => {ServiceSelect()}}
                    >
                        <SearchIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >
                            <Title>Ventas</Title>
                        </Box>
                        <Table size="small">
                            <TableHead>
                                <TableRow alignItems="center">
                                    <TableCell align="center">Fecha Solicitud</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Valor producto</TableCell>
                                    <TableCell align="center" >Estado Solicitud</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadUser ?
                                    <>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            alignContent="center"
                                            wrap="nowrap"
                                        >
                                            <ReactLoading
                                                type={"bars"}
                                                color={"#000"}
                                                height={50}
                                                width={50}
                                            />
                                        </Grid>
                                    </>
                                    : data.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="center">{`${formatData(row.created)}`}</TableCell>
                                            <TableCell align="center">{row.datosPersona === undefined ? "----" : row.datosPersona.nombrecompleto}</TableCell>
                                            <TableCell align="center">{
                                                row.valormontoaprobado === undefined || row.valormontoaprobado === null ?
                                                    `${formatMoney(row.valormontorequerido)}` : `${formatMoney(row.valormontoaprobado)}`
                                            }
                                            </TableCell>
                                            <TableCell align="center">{row.estadoSolicitud === undefined ? "----" : row.estadoSolicitud.estado}{row.subEstado && `/${row.subEstado.subestado}`}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page}

                            />
                        </Grid>
                        <br />

                    </Paper>
                </Grid>
            </Grid>

        </>

    )
}