//React
import React, { useEffect, useState, createRef } from 'react';
import ReactLoading from "react-loading";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { store } from 'react-notifications-component';
//Redux
import { ID_SUCURSAL, ID_COMERCIO, USER_ROL, ID_SELLER, ID_USER, CODE_COMERCIO } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icon Material
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';

//Componet Material
import { Grid, Paper, TableContainer, TextField, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, IconButton } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";

import './solicitud.css';

//Local Component
import Paginator from '../utils/paginator'
import ModalUtils from '../modals/modalAsignar';
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import {
    geSolicitudes,
    updateVendedor,
    getSolicitudesByFilter,
    statusRequest,
    statusComercio,
    getVendedoresSucursal,
    getVendedoresComercio,
    getUsersComerce,
    geListSucuralById,
    getPresolicitudByFilter,
    getPresolicitudByRol,
    getPresolicitudByRolCommerce,
} from '../domain/api/PrestapolisApi'

import {
    formatData,
    formatMoney
}
    from '../utils/validation'
import ModalCalculadora from '../modals/modalCalculadora';
import ModalReenviarMensaje from '../modals/modalReenviarMensaje';



//My style
const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    filterSolicitudes: {
        backgroundColor: "red !Important",
        width: "50px !Important"
    },
    search: {
        borderRadius: "5px",
        height: "10px",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 1px 1px rgb(0 0 0 / 8%)"
    },
    css1r4vtzz: {
        width: "100%"
    }
}));



export const PreSolicitudes = ({ }) => {
    //const classes = SolcitudesStyle();
    const classes = useStyles();
    const [data, setData] = useState([])
    const [dataRol, setDataRol] = useState([])
    const [isOpenModalReeviar, setisOpenModalReenviar] = useState(false);
    const [state, setState] = useState(false)
    const [isLoadUser, setisLoadUser] = useState(false)
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(20)
    const [filter, setFilter] = useState("")
    const [totalElements, settotalElements] = useState(0)
    const [totalPage, settotalPage] = useState(0)
    const [openMondal, setopenMondal] = useState(false)
    const [errorFile, seterrorFile] = useState(false)
    const [succesFile, setsuccesFile] = useState(false)
    const [dateClient, setdateClient] = useState([])

    const [listEstates, setlistEstates] = useState([])
    const [arrayEstates, setarrayEstates] = useState([])
    const [listComerce, setlistComerce] = useState([])
    const [arrayComerce, setarrayComerce] = useState([])
    const [userDni, setuserDni] = useState("")
    const [messageErro, setmessageErro] = useState("")
    const [listVendedor, setlistVendedor] = useState([])
    const [pageVendor, setpageVendor] = useState("")
    const [sizeVendor, setsizeVendor] = useState("")
    const [filterVendor, setfilterVendor] = useState("")
    const [vendorDNI, setvendorDNI] = useState("")
    const [asing_sucursalId, setAsing_sucursalId] = useState("")
    const [filterEstates, setFilterEstates] = useState("")
    const [filterComercios, setFilterComercios] = useState("")
    const [listSucursalByUser, setlistSucursalByUser] = useState([])

    let idUser = localStorage.getItem(ID_USER)
    let idSeller = localStorage.getItem(ID_SELLER)
    let idSucursal = localStorage.getItem(ID_SUCURSAL)
    let idComercio = localStorage.getItem(ID_COMERCIO)
    let rolLogin = localStorage.getItem(USER_ROL)
    let rolLoginPS = localStorage.getItem(USER_ROL)
    const dispatch = useDispatch();
    const styleSearch = classes.search

    useEffect(async () => {

        if (rolLogin === "ROLE_SELLER") {
            getListSucursales(idSucursal)
            getStatusRequest();
            getStatusComercios();
           getUserCommerce(idComercio, pageVendor, sizeVendor, filterVendor);
            
            loadDataTable(idSeller, page, size, filter, 22);
            
        }
        else  {
            getListSucursales(idSucursal)
            getStatusRequest();
            getStatusComercios();
            getUserCommerce(idComercio, pageVendor, sizeVendor, filterVendor);
            loadRol(idComercio, page, size, filter,  12);
            
        }
       
    }, [page], [totalElements]);

 
    const reloadPage = () => {
        if(rolLogin === "ROLE_SELLER"){
            getStatusRequest();
            getStatusComercios();
            getUserCommerce(idComercio, pageVendor, sizeVendor, filterVendor);
            loadDataTable(idSeller, page, size, filter, 22);
            
        }else{
            getStatusRequest();
            getStatusComercios();
            getUserCommerce(idComercio, pageVendor, sizeVendor, filterVendor);
            loadRol(idComercio, page, size, filter,  12);
        }

       
       
    }



    const getStatusRequest = () => {
        statusRequest(dispatch)
            .then((response) => {
                let data = response.respuesta;
                let lista = [];
                data.map((item) => {
                    lista.push({
                        id: item.id,
                        value: item.estado,
                        label: item.estado,
                    });
                });

                setarrayEstates(lista)
            })
            .catch((err) => {
             
                if (err.status === 401) {

                }
            });
    }



    const getStatusComercios = () => {
        statusComercio(dispatch)
            .then((response) => {
                
                let data = response.respuesta;
                let lista = [];

                data.map((item) => {
                    lista.push(
                        { id: item[0], label: item[1], value: item[1] }
                    );
                });

                setarrayComerce(lista)
            })
            .catch((err) => {
             
                if (err.status === 401) {
                    //this.props.onSetAuthUser(null);
                }
            });
    }

    //Metodo para listar las sucursales de un usuario
    const getListSucursales = (list) => {
        geListSucuralById(dispatch, list)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setlistSucursalByUser(response.respuesta)
                }
            })
            .catch((err) => {
               
            })

    }

    //Metodo para setear user y listar vendedores
    const setUsuerComerce = (value) => {

        setvendorDNI(parseInt(value))
        let sucursales = ""
        listVendedor.map((item) => {
            if (parseInt(value) === parseInt(item.id)) {
                sucursales = item.sucursales
            }
        })

        getListSucursales(sucursales);
    }


    //Metodo para listar usuario del comercio
    const getUserCommerce = (id, page, size, filter) => {

        getUsersComerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let data = response.content;
                    setlistVendedor(data);
                } else {
                   
                }
            })
            .catch((error) => {
               
                if (error.status === 401) {

                }
            });

    };

//Metodo para ROL
const loadRol = (idComercio, page, size, filter, subestado) => {
    setisLoadUser(true);
    getPresolicitudByRol(dispatch, idComercio, page, size, filter,  subestado)
        .then((response) => {
            if (response.code === "0000") {
                let totalPages = response.totalPages;
                let totalElements = response.totalElements;
                let data = response.content;
                setData(data);
                settotalElements(totalElements);
                settotalPage(totalPages);
                
                setisLoadUser(false);
            } else {      
                setisLoadUser(false)
            }
        })
        .catch((error) => {          
            if (error.status === 401) {
            }
        });
};

//Metodo para ROL si es comercio
const loadRolComercio = (idComercio, page, size, filter, subestado) => {
    setisLoadUser(true);
    getPresolicitudByRol(dispatch, idComercio, page, size, filter,  subestado)
        .then((response) => {
            if (response.code === "0000") {
                let totalPages = response.totalPages;
                let totalElements = response.totalElements;
                let data = response.content;
                setData(data);
                settotalElements(totalElements);
                settotalPage(totalPages);
                
                setisLoadUser(false);
            } else {      
                setisLoadUser(false)
            }
        })
        .catch((error) => {          
            if (error.status === 401) {
            }
        });
};



    //Metodo para cargar datos de la tabla
    const loadDataTable = (id, page, size, filter, subestado) => {
        setisLoadUser(true);
        getPresolicitudByFilter(dispatch, id, page, size, filter, subestado)
            .then((response) => {
                if (response.code === "0000") {
                    let totalPages = response.totalPages;
                    let totalElements = response.totalElements;
                    let data = response.content;

                    setData(data);
                    settotalElements(totalElements);
                    settotalPage(totalPages);

                    setisLoadUser(false);
                } else {
  
                    setisLoadUser(false)
                }

            })
            .catch((error) => {
              
                if (error.status === 401) {

                }
            });
    };


    //Filter Estados    
    function getDropdownButton({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: Seleccionar todos`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} Seleccionado`;
        }
    }

    //Setear datos asincronicamante
    const setFilterSearch = (setState, arrayFilter) => {
        return new Promise((resolve, reject) => {
            resolve(setState(arrayFilter));
        })
    }

    //Metdo para gestionar filtro por estados
    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            if (event.name === "comercios-filter") {
                let idString = []
                arrayComerce.map((item) => {
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setFilterComercios(idString)
            } else if (event.name === "estados-filter") {
                let idString = []
                arrayEstates.map((item) => {
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setFilterEstates(idString)
            }
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            if (event.name === "comercios-filter") {
                setFilterComercios("")
            } else if (event.name === "estados-filter") {
                setFilterEstates("")
            }
            this.setState([]);
        } else if (event.action === "deselect-option") {
            if (event.name === "comercios-filter") {
                let idString = []
                let arrayFilter = value.filter((o) => o.value !== "*")
                arrayFilter.map((item) => {
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setFilterComercios(idString)
            } else if (event.name === "estados-filter") {
                let idString = []
                let arrayFilter = value.filter((o) => o.value !== "*")
                arrayFilter.map((item) => {
                    idString.push(
                        item.id
                    )
                })
                idString = idString.toString();
                setFilterEstates(idString)
            }
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            if (event.name === "comercios-filter") {

                let select = [];
                let stringSelect = ""
                value.map((item) => {
                    select.push(
                        item.id
                    )
                })

                stringSelect = select.toString();
                setFilterSearch(setFilterComercios, stringSelect)
                    .then((response) => {
                        // loadDataTable(idComercio,page,size,filter, filterComercios, filterEstates)
                    })

            } else if (event.name === "estados-filter") {

                let select = [];
                let stringSelect = ""
                value.map((item) => {
                    select.push(
                        item.id
                    )
                })

                stringSelect = select.toString();
                // setFilterEstates(stringSelect)
                setFilterSearch(setFilterEstates, stringSelect)
                    .then((response) => {
                        // loadDataTable(idComercio,page,size,filter, filterComercios, filterEstates)
                    })
            }


            this.setState(value);
        }
    }



    //Metodo para actualizar vendedores asignados
    const updateVendor = () => {

        if (userDni === "") {

            setmessageErro("El número de documento es requerido")
            seterrorFile(true)
            setTimeout(() => {
                seterrorFile(false)
            }, 4000);

        } else {

            let userDocument = dateClient.datosPersona.documentNumber;
            let vendorID = rolLogin != "ROLE_SELLER" ? vendorDNI : idSeller;
            userDocument = userDocument.length > 10 ? userDocument.slice(6) : userDocument;

            if (userDocument === userDni) {


                const request = {
                    solicitud: {
                        id: dateClient.id
                    },
                    vendedore: vendorID,
                    datosPersona: {
                        documentNumber: userDni
                    },
                    usuario: idSeller,
                    sucursales: asing_sucursalId
                }


             
                updateVendedor(dispatch, request)
                    .then((response) => {
                        if (response.codigoRespuesta === "0000") {
                            setsuccesFile(true)
                            setTimeout(() => {
                                setsuccesFile(false)
                                setopenMondal(false)
                            }, 3000)

                            // getListSolicitud(idComercio,page,size,filter,"1")
                            if(rolLogin === "ROLE_SELLER"){
                                loadDataTable(idSeller, page, size, filter, 22)
                            }else{
                                loadRol(idComercio, page, size, filter, 12);
                            }
                           
                            

                        } else {
                            setmessageErro(response.mensaje)
                            seterrorFile(true)
                            setTimeout(() => {
                                seterrorFile(false)
                            }, 4000);
                        }

                    })
                    .catch((err) => {
                        setmessageErro("¡Se presento un error en el servicio!")
                        seterrorFile(true)
                       
                        setTimeout(() => {
                            seterrorFile(false)
                        }, 4000);
                    });

            } else {

                setmessageErro("El número de documento no coincide")
                seterrorFile(true)
                setTimeout(() => {
                    seterrorFile(false)
                }, 4000);
            }
        }

    }

    const handledClickMondal = () => {
        updateVendor();
       
    }

    const clearModalAsing = () => {
        setvendorDNI("");
        setuserDni("");
        setAsing_sucursalId("");
    }

    const reloadTable = () => {
        if(rolLogin === "ROLE_SELLER"){
            loadDataTable(idSeller, 0, size, "", 22);
             setFilterComercios("");
             setFilter("")
             setlistEstates([])
        }else{
            loadRol(idComercio, 0, size, "", 12);
             setFilterComercios("");
             setFilter("")
             setlistEstates([])
        }
       
    }

        ;

    //METODO PARA COPIAR BTN URL 
    const onLinkBtnClick = (row) => {
        let comerceCode = localStorage.getItem(CODE_COMERCIO)
        let url = `https://${ process.env.REACT_APP_URL}/${comerceCode}/token=${row.token}`;
        navigator.clipboard.writeText(url);
        store.addNotification({
            title: "URL copiado:",
            message: url,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 6000,
            }
        });
    }

    return (
        <>

            <ModalCalculadora
                setopenMondal={setopenMondal}
                isOpen={openMondal}
                data={dateClient}
                setdata={setdateClient}
                reloadView={reloadPage}
            />

           {isOpenModalReeviar && <ModalReenviarMensaje
                isOpen={isOpenModalReeviar}
                setopenMondal={setisOpenModalReenviar}
                data={dateClient}
            >
            </ModalReenviarMensaje>}

            <Grid item xs={12} >
                <MigaPan namePage="Pre Solicitudes" to="/" >
                    Prestápolis
                </MigaPan>
            </Grid>

            <Grid container spacing={1}>

                <Grid item xs={3} sm={4} md={4}>
                   
                </Grid>

                <Grid item xs={2} sm={1} md={1} style={{ textAlign: 'center' }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(e) => { reloadTable() }}
                    >
                        <RotateLeftIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>

                <Grid item xs={5} sm={6} md={6}>
                    <TextField
                        // id="document"
                        placeholder='Buscar'
                        variant="outlined"
                        inputProps={{ className: styleSearch }}
                        fullWidth
                        value={filter}
                        onChange={((e) => { setFilter(e.target.value) })}
                    />

                </Grid>

                <Grid item xs={1} style={{ textAlign: 'center', maxWidth: "50%" }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(e) => { 
                            if(USER_ROL === 'ROLE_SELLER'){
                                loadDataTable(idSeller, page, size, filter, 22)
                                
                            }else{
                                loadRol(idComercio, page, size, filter,  12) }}
                            }
                    >
                        <SearchIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>

            </Grid>



            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >
                            <Title>Solicitudes</Title>
                        </Box>

                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow alignItems="center">
                                        <TableCell align="center">Fecha Solicitud</TableCell>
                                        <TableCell align="center">Apellido</TableCell>
                                        <TableCell align="center">Valor producto</TableCell>
                                        <TableCell align="center" >Valor abono</TableCell>
                                        <TableCell align="center">Negociacion</TableCell>
                                        <TableCell align="center">Reenviar</TableCell>
                                        <TableCell align="center">URL</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoadUser ?
                                        <>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                wrap="nowrap"
                                            >
                                                <ReactLoading
                                                    type={"bars"}
                                                    color={"#000"}
                                                    height={50}
                                                    width={50}
                                                />
                                            </Grid>
                                        </>
                                        : data !== null && data !== undefined && data.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="center">{`${formatData(row.created)}`}</TableCell>
                                                <TableCell align="center">{row.datosPersona.firstLastName}</TableCell>
                                                <TableCell align="center">{
                                                    row.montoAprobado === undefined || row.montoAprobado === null ?
                                                        row.valormontoaprobado === undefined || row.valormontoaprobado === null ?
                                                            `${formatMoney(row.valormontorequerido)}`
                                                            : `${formatMoney(row.valormontoaprobado)}`
                                                        : `${formatMoney(row.montoAprobado)}`
                                                }
                                                </TableCell>
                                                <TableCell align="center">{row.abonoFinal !== undefined ? formatMoney(row.abonoFinal) : formatMoney(row.abono)}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        // disabled={true}
                                                        onClick={(e) => {
                                                            setopenMondal(!openMondal);
                                                            setdateClient(row);
                                                        }}
                                                    >
                                                        Negociación
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            setdateClient(row)
                                                            setisOpenModalReenviar(true)
                                                        }}
                                                    >
                                                        REENVIAR
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => {
                                                            onLinkBtnClick(row);
                                                        }
                                                        }
                                                    >
                                                        URL
                                                    </Button>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page}

                            />
                        </Grid>
                        <br />

                    </Paper>
                </Grid>
            </Grid>
        </>


    )



}