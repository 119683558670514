export const ACCESS_TOKEN = "token";
export const ID_USER="id";
export const ID_SELLER ="vendedor_id";
export const ID_SUCURSAL="idSucursal";
export const ID_COMERCIO="idComercio";
export const BAAS="baas";
export const CODE_COMERCIO="comerceCode";
export const ERROR_SERVICE="false";
export const USER_ROL = "rol"
export const ID_CREDITOS = "idCreditos";

export const NAME_COMERCIO="nameComercio"
export const NAME_SUCURSAL="nameSucursal"
export const URL_COMERCIO = "urlComercio"

export const USER_LOGIN = "userLogin";
export const SUCURSAL_LOGIN = "sucursal";
export const USER_AUTH0 = "userAuth0";
export const ERROR_LOGIN = "error_login"
export const ISADMIN_USER = "isAdmin"
export const CHANGE_PASS = 'changePass'
export const EMAIL_CHANGE = 'emailUser'
export const RECAUDO = "recaudo"
export const SUCURSAL = "key_sucursal"
