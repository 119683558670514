
//React 
import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import 'react-notifications-component/dist/theme.css'
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';

//Redux
import { useDispatch } from 'react-redux';

//Icons Material
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';

//Components Material
import { makeStyles } from '@material-ui/core/styles';

import {
    Paper,
    Grid,
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TableContainer,
    TextField,

} from '@material-ui/core';

//importar api
import {
    getPaymentByCredic
} from '../domain/api/PrestapolisApi'


//Local Component
import Paginator from '../utils/paginator';
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import { ID_COMERCIO } from '../../app/api-client/constants';
import { formatData, dateFormatwithoutTime, formatMoney } from '../utils/validation';


//stylos
const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center",
    }
}));


export const Recaudos = ({ }) => {

    const classes = useStyles();
    const [isLoadUser, setisLoadUser] = useState(false)
    const [data, setData] = useState([])
    const [totalElements, settotalElements] = useState(0)
    const [page, setPage] = useState(0)
    const [size, setSize] = useState(10)
    const [filter, setFilter] = useState("")
    const [listSucursal, setlistSucursal] = useState([])
    const styleSearch = classes.search
    const dispatch = useDispatch();

    let idComerce = localStorage.getItem(ID_COMERCIO);

    const history = useHistory();


    //Metodo para cargar datos de la tabla
    const getCreditos = () => {
        setisLoadUser(true);
        getPaymentByCredic(dispatch, filter, idComerce)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setData(response.respuesta);
                    setisLoadUser(false);
                } else {
                   
                    setisLoadUser(false)
                    store.addNotification({
                        title: "Error",
                        message: response.respuesta.setData("codigo", "código"),
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 7500,
                        }
                    });
                }

            })

            .catch((err) => {
                
                store.addNotification({
                    title: "Error",
                    message: "Error enviando el pago, por favor comunicarce con soporte@prestapolils.com",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 9500,
                    }
                });

                setisLoadUser(false)
            })
    };



    return (
        <>

            <Grid item xs={12} >
                <MigaPan namePage="Recaudo" to="/" >
                    Prestápolis
                </MigaPan>
            </Grid>

            <Grid container spacing={1} >


                <Grid item xs={true} sm={true} md={true} >

                    <Grid >
                        <Button
                            variant="contained"
                          style={{color:'#FFFFFF',
                          background:'#2BAEB3'}}
                            size="large"
                            onClick={(e) => {
                                history.push({
                                    pathname: `/recaudos/historico/`,
                                    search: '',
                                })
                            }}
                        >
                            Historico
                        </Button>
                    </Grid>

                </Grid>

                <Grid item style={{ textAlign: 'start' }}>
                      
                    <IconButton
                        size="large"
                        color="Primary">
                        <RotateLeftIcon />
                    </IconButton>
                    </Grid>
                <Grid item xs={true} sm={true} md={true}>
                    <TextField
                        // id="document"
                        placeholder='CEDULA'
                        variant="outlined"
                        inputProps={{ className: styleSearch }}
                        fullWidth
                        value={filter}
                        onChange={((e) => { setFilter(e.target.value) })}
                    />
                </Grid>

                <Grid item xs={1} style={{ textAlign: 'start', maxWidth: "50%" }}>
                    <IconButton
                        size="large"
                        color="Primary"
                        onClick={(filter) => {
                            getCreditos(filter)
                        }}>
                        <SearchIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Grid>
            </Grid>



            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}>
                            <Title style={{fontfamily:'Montserrat'}}>Creditos</Title>
                        </Box>

                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow alignItems="center">
                                        <TableCell align="center">Fecha credito</TableCell>
                                        <TableCell align="center">id de credito</TableCell>
                                        {/* <TableCell align="center">Cargo</TableCell> */}
                                        <TableCell align="center" >Cuotas</TableCell>
                                        <TableCell align="center">Pago minimo</TableCell>
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Fecha proximo pago</TableCell>
                                        <TableCell align="center">Pago</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoadUser ?
                                        <>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                wrap="nowrap"
                                            >
                                                <ReactLoading
                                                    type={"bars"}
                                                    color={"#000"}
                                                    height={50}
                                                    width={50}
                                                />
                                            </Grid>
                                        </>
                                        : data.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="center">{`${formatData(row.fechaCreacion.length == 0 ? "-" : row.fechaCreacion)}`}</TableCell>
                                                <TableCell align="center">{row.id.length == 0 ? "-" : row.id}</TableCell>
                                                <TableCell align="center">{((row.cuotaspagadas == null ? "-" : row.cuotaspagadas) + "/" +  (row.cuotas == null ? "-" : row.cuotas))}</TableCell>
                                                <TableCell align="center">{`${formatMoney(row.gastoAcumulados.length == 0 ? "-" : row.gastoAcumulados[0].pagoMinimo)}`}</TableCell>
                                                <TableCell align="center">{row.estadoCredito.length == 0 ? "-" : row.estadoCredito.estado}</TableCell>
                                                <TableCell align="center">{`${dateFormatwithoutTime(row.fechaproximopago.length == 0 ? "-" : row.fechaproximopago)}`}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        // disabled={row.estadoSolicitud.id != 10? true: false}
                                                        onClick={(e) => {
                                                            history.push({
                                                                pathname: `/recaudos/payment/${row.id}`,
                                                                search: '',
                                                                state: {
                                                                    credict: row
                                                                }
                                                            })
                                                        }}

                                                    >
                                                        Pagar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap">
                            <Paginator
                                totalElement={Math.round(totalElements / size)}
                                setPage={setPage}
                                page={page} />
                        </Grid>
                        <br />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
