import { useForm } from 'react-hook-form';


//redux
import { useDispatch } from 'react-redux';

//React 
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ReactLoading from "react-loading";
import 'react-notifications-component/dist/theme.css'

import { store } from 'react-notifications-component';
import { formatMoney } from '../utils/validation';


//Components Material
import { makeStyles } from '@material-ui/core/styles';
import MigaPan from '../../app/orussystem/component/MigaPan';
import CardContent from '@material-ui/core/CardContent';

//local componente
import ModalRegistroPago from '../modals/modalRegistroPago';
import ModalAlertInfoPayment from '../modals/modalaAlertInfoPayment';
import ModalAlertInfoPaymentCcampos from '../modals/modalAlertInforPaymentCcampos';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
    Paper,
    Grid,
    Button,
    Box,
    TextField,
} from '@material-ui/core';

//importar api
import {
    getOptCommerceCredict,
    getSucursalByIdCommerce,
    geListSucuralById
} from '../domain/api/PrestapolisApi'

//Local componente
import Title from '../../app/orussystem/component/Title';
import {currentDateFormat } from '../utils/validation';

//iconos
import Typography from '@material-ui/core/Typography';
import { HelpValidate } from '../../user/utils/helpValidate';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { purple } from '@material-ui/core/colors';
import { ID_COMERCIO } from '../../app/api-client/constants';

//My style
const useStyles = makeStyles((theme) => ({

    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    root: {
        minWidth: "100%",
        marginBottom: "20px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    letraNegrita: {
        negrita: { fontWeight: 'bold' },
    }

}));



export const InfoPayment = ({ }) => {

    const classes = useStyles();
    const [isOpenModalRegistroPago, setisOpenModalRegistroPago,] = useState(false)
    const [isOpenModalAlertInfoPaymentCcampos, setisOpenModalAlertInfoPaymentCcampos,] = useState(false)
    const [isOpenModalAlertInfoPayment, setisOpenModalAlertInfoPayment,] = useState(false)
    const [celular_pagador, setCelular_pagador] = useState("");
    const [confirma_celular, setConfirma_celular] = useState("");
    const [isLoadUser, setisLoadUser] = useState(false)
    const [listSucursal, setlistSucursal] = useState([]);
    
    const [filter, setFilter] = useState("")
    const [data, setData] = useState([])
    const dispatch = useDispatch();
    const [valor_prod, setValor_prod] = useState("");
    const [isCardPlan, setisCardPlan] = useState(false);
    const [abono_prod, setAbono_prod] = useState("");
    const [comerceData, setComerceData] = useState({});
    const [credict, setcredict] = useState("");

    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();

    let idComercio = localStorage.getItem(ID_COMERCIO);
    
    const location = useLocation()

    useEffect(() => {
        
        setcredict(location.state.credict );

    },[])



    //Metodo de capaturar fecha
    var today = new Date();
    var now = today.toLocaleString();
  
    

    const formatPesos = (value) => {
        let number = ""
        number = new Intl.NumberFormat(["ban", "id"]).format(value)
        return number
    }

    const validatePhone = () => {

        if (!celular_pagador || !confirma_celular) {
            setisOpenModalAlertInfoPaymentCcampos(true)
            return
        }

        if (celular_pagador != confirma_celular) {
            setisOpenModalAlertInfoPayment(true)
            return
        }

        if(!valor_prod){
            alert("El valor es requerido");
            return
        }

        getOptCommerceModal();
        //setisOpenModalRegistroPago(true)
        
       
    }
    const ClearForm = () => {
        setValor_prod("")
        setCelular_pagador("")
        setConfirma_celular("")
    }

    //Metodo para cargar datos de la tabla

    const getOptCommerceModal = () => {
        setisLoadUser(true);
          let idComerce = localStorage.getItem(ID_COMERCIO);
        setisOpenModalRegistroPago(true)
        getOptCommerceCredict(dispatch, celular_pagador, credict.id,  valor_prod.replace(".",""), idComerce, credict.documento, false)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setisLoadUser(false);
                    setisOpenModalRegistroPago(true)
                } else {
                    
                    setisLoadUser(false)
                }

            })
            .catch((error) => {
             
                setisLoadUser(false)

                store.addNotification({
                    title: "Error!",
                    message: "Se Presento un error inesperado, comuniquese con info@prestapolis.com",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeOut"],
                    animationOut: ["animate__animated", "animate__fadeIn"],
                    dismiss: {
                        duration: 5000,
                        // onScreen: true
                    }
                });

                if (error.status === 401) {

                }
            });

    };

    const GetSucursales = (id, page, size, filter) => {
        getSucursalByIdCommerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    let data = response.content;
                    let lista = [];
                    data.map((item) => {
                        lista.push({
                            id: item.id,
                            value: item.id,
                            label: item.nombre,
                        });
                    });
                    setlistSucursal(lista)
               
                }
            })
            .catch((err) => {
               
            })
    }
    useEffect(() => {
        GetSucursales(idComercio, 0, 1000, "")
      }, [])

    return (
        <>

            <ModalAlertInfoPaymentCcampos
                isOpen={isOpenModalAlertInfoPaymentCcampos}
                setopenMondal={setisOpenModalAlertInfoPaymentCcampos}
            >
            </ModalAlertInfoPaymentCcampos>

            <ModalAlertInfoPayment
                isOpen={isOpenModalAlertInfoPayment}
                setopenMondal={setisOpenModalAlertInfoPayment}
            >
            </ModalAlertInfoPayment>

            <ModalRegistroPago
                isOpen={isOpenModalRegistroPago}
                setopenMondal={setisOpenModalRegistroPago}
                celular = {celular_pagador}
                valor_prod = {valor_prod}
                listSucursal={listSucursal}
              
            >
            </ModalRegistroPago>

            <Grid item xs={12} >
                <MigaPan
                    namePage="Informacion pago"
                    to="/recaudos"
                    namePage2="Basculas"
                    to2="/basculas/"
                >
                    Prestápolis
                </MigaPan>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} >
                <form autocomplete="off">
                    <Paper className={classes.paper} >
                        <Box md={6}
                            component="span"
                            style={{ textAlign: "start" }}>
                            <Title >Información de Pagos</Title>
                        </Box>

                        <CardContent style={{ textAlign: "start", paddingBottom: "10px" }}>

                            <Typography style={{ textAlign: "start", marginTop: '10px', fontWeight: 700, fontSize: 15 }}  >
                                {"Nombre: " + credict?.cupo?.datosPersona.nombrecompleto}
                            </Typography>
                            <Typography style={{ textAlign: "start", fontWeight: 700, fontSize: 15 }}>
                                {"Documento: " + credict.documento}
                            </Typography>
                            <Typography style={{ textAlign: "start", fontWeight: 700, fontSize: 15 }}>
                                {"Número de credito: " + credict.id}
                            </Typography>
                            <Typography style={{ textAlign: "start", fontWeight: 700, fontSize: 15 }}>   
                            
                            Pago mínimo: {`${formatMoney(credict.gastoAcumulados == null ? "-" : credict.gastoAcumulados[0].pagoMinimo)}`}
                            </Typography>
                            <Typography style={{ textAlign: "start", fontWeight: 700, fontSize: 15 }}>
                             Pago total:   {`${formatMoney(credict.gastoAcumulados == null ? "-" : credict.gastoAcumulados[0].pagoTotal)}`} 
                            </Typography>

                           

                            <div style={{ marginTop: 30 }} >
                                <label  style={{ fontWeight: 400, fontSize: 18 }}>Fecha de pago</label>
                                <TextField
                                    // id="document"
                                    placeholder=''
                                    variant="outlined"
                                    fullWidth
                                    value={now}                                   
                                />
                            </div>
                         
                            <div style={{ marginTop: 20 }}>
                                <label style={{ fontWeight: 400, fontSize: 18 }}>Valor a pagar</label>

                                <TextField
                                    // id="document"
                                    id="valor_prod"
                                    name="valor_prod"
                                    startAdornment={<InputAdornment position="start"><AttachMoneyIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                    labelWidth={150}
                                    value={valor_prod}
                                    autocomplete="off"
                                    inputProps={{ className: "label__color" }}
                                    variant="outlined"
                                    fullWidth
                                    type="tel"
                                    {...HelpValidate().calcAbonoCrete(register('valor_prod', {
                                        required: "Por favor ingresa un valor del producto valido.",
                                        minLength: {
                                            value: 2,
                                            message: 'Por favor ingresa un valor del producto valido'
                                        },
                                    }), setValor_prod, setisCardPlan, setAbono_prod, comerceData)}
                                    helperText={errors.valor_prod?.message} />
                            </div>


                         </CardContent>

                        <Paper className={classes.paper} >


                            <CardContent style={{ textAlign: "start", marginTop: '20px' }} >

                                <div style={{ marginTop: 30 }} >
                                    <label style={{ fontWeight: 400, fontSize: 18 }}>Celular del pagador</label>
                                    <TextField

                                        id="celular_pagador"
                                        name="celular_pagador"
                                        placeholder=''
                                        variant="outlined"
                                        fullWidth
                                        autocomplete="off"
                                        value={celular_pagador.replace(".","")}
                                        defaultValue={celular_pagador}
                                        {...HelpValidate().validateNumber(register('celular_pagador', {
                                            required: "El Celular es requerida.",
                                            minLength: {
                                                value: 10,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 10, 10, setError, clearErrors, setCelular_pagador)}
                                        error={!!errors.celular_pagador}
                                        helperText={errors.celular_pagador?.message}
                                    />
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <label style={{ fontWeight: 400, fontSize: 18 }}>Confirma el celular</label>
                                    <TextField

                                        id="confirma_celular"
                                        name="confirma_celular"
                                        placeholder=''
                                        variant="outlined"
                                        fullWidth
                                        autocomplete="off"
                                        value={confirma_celular.replace(".","")}
                                        defaultValue={confirma_celular}
                                        {...HelpValidate().validateNumber(register('confirma_celular', {
                                            required: "El Celular es requerida.",
                                            minLength: {
                                                value: 10,
                                                message: 'El Celular debe tener minimo 10 caracteres'
                                            },
                                        }), 10, 10, setError, clearErrors, setConfirma_celular)}
                                        error={!!errors.confirma_celular}
                                        helperText={errors.confirma_celular?.message}
                                    />
                                </div>

                            </CardContent>

                        </Paper>
                        
                    </Paper>
                    </form>
                    <Grid style={{ marginTop: '35px' }}>
                    
                        <Button
                            // disabled={isContinuo}
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ textTransform: "none", width: "inherit" }}
                            onClick={(e) => {
                               validatePhone();
                               


                            }}>
                               
                            Enviar código de aprobación
                           {isLoadUser && <ReactLoading
                                            type={"bars"}
                                            color={"#fff"}
                                            height={50}
                                            width={50}
                                        />}
                        </Button>

                    </Grid>
                </Grid>
            </Grid>
        </>




    );

}