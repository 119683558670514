//React
import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";

//Componet Material
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, IconButton } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

//Icons
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Typography from '@material-ui/core/Typography';


//Local Component
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import {
    geSolicitudes,
    updateVendedor,
} from '../domain/api/PrestapolisApi'

import {
    formatData,
    formatMoney
}
    from '../utils/validation'



//My style
const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    root: {
        minWidth: "100%",
        marginBottom: "20px"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));


export const MediosPagos = ({ }) => {

const classes = useStyles();

    return (
        <>

            <Grid item xs={12} >
                <MigaPan namePage="Medios de pago" to="/" >
                    Prestápolis
                </MigaPan>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Paper className={classes.paper} >
                        <Box
                            m={2}
                            component="span"

                            style={{ textAlign: "center" }}
                        >
                            <Title >Medios De Pagos</Title>
                        </Box>


                        <CardContent style={{ textAlign: "center", paddingBottom: "20px" }}>
                            <Typography className={classes.pos} color="textSecondary">
                                Recuerda que los clienten pueden pagar por PSE desde cualquier dispositivo haciendo click
                                {<Link href="https://sucursal.prestapolis.com/" target="_blank">   sucursal.prestapolis.com</Link>}
                            </Typography>
                            <Typography className={classes.pos} color="textSecondary">
                                También pueden pagar en EFECTIVO a través de nuestros convenios en las REDES DE PAGO:
                            </Typography>
                        </CardContent>
                        {/* <CardActions>
                                <Button size="small">{<Link href="https://www.zonapagos.com/t_prestapolis" target="_blank">Pagos</Link>}</Button>
                            </CardActions> */}


                        <Table size="small" style={{ textAlign: "left" }}>
                            <TableHead>
                                <TableRow alignItems="center">
                                    <TableCell align="center">Red de Pago</TableCell>
                                    <TableCell align="center">Convenio</TableCell>
                                    <TableCell align="center">Referencia</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* <TableRow >
                                    <TableCell align="center">Baloto</TableCell>
                                    <TableCell align="center">950715</TableCell>
                                    <TableCell align="center">265 + # Cédula (ejemplo 26512345678)</TableCell>
                                </TableRow> */}
                                <TableRow >
                                    <TableCell align="center">Punto Red</TableCell>
                                    <TableCell align="center">110342</TableCell>
                                    <TableCell align="center">265 + # Cédula</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="center">Wester Union</TableCell>
                                    <TableCell align="center">110342</TableCell>
                                    <TableCell align="center">265 + # Cédula</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="center">Efecty</TableCell>
                                    <TableCell align="center">113027 o Prestapolis</TableCell>
                                    <TableCell align="center">265 + # Cédula</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="center">Bancolombia</TableCell>
                                    <TableCell align="center">54245</TableCell>
                                    <TableCell align="center">{<Link href="https://wa.me/573116952930" target="_blank">Solicita la referencia vía WhatsApp + 57 3116952930 {<IconButton color="primary" aria-label="upload picture" component="span" ><WhatsAppIcon /></IconButton>}</Link>}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <div  style={{textAlign:"center", padding:10, fontSize: "16px", color:"#757575"}}><b>Mayor información en </b><Link href="https://www.prestapolis.com/pagos" target="_blank">prestapolis.com/pagos</Link></div>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}